import React, {Fragment} from "react";
import {FieldArray} from "formik";
import {Form} from "../form/formik";
import PropTypes from "prop-types"
import {SldsButton, SldsButtonGroup, SldsCheckboxField, SldsFormElement, SldsFormElementCompound, SldsFormElementRow, SldsInputField, SldsTextareaField} from "../form/formElements";
import {Accordion, AccordionPanel} from "../accordion";


export function GenericDataTableConfigForm(props) {
    const {onCancel, onRestoreDefault, formik, id} = props;

    const cols = formik?.values?.cols || [];
    const readOnly = formik?.status?.readOnly || false;
    return <Form id={id}>
        <FieldArray name={"cols"} render={(arrayHelpers) => {
            return <Fragment>
                <SldsCheckboxField name={`hasFilter`} inlineLabel={"Show Filter"}/>
                <SldsInputField label={"Default Filter"} name={`defaultFilterString`}/>
                <Accordion>
                    {cols.map((col, idx) => {
                        const cell = col.cell || {};
                        const colTitle = `Column ${idx + 1}: ${col.heading || ""}`;
                        return <AccordionPanel
                            key={idx}
                            id={idx}
                            summary={colTitle}
                            panelContentActions={!readOnly && <React.Fragment>
                                <SldsButtonGroup>
                                    <SldsButton iconCategory={"utility"} iconName={"arrowup"} iconVariant={"border"} variant={"icon"} disabled={idx === 0} onClick={() => arrayHelpers.move(idx, idx - 1)}/>
                                    <SldsButton iconCategory={"utility"} iconName={"arrowdown"} iconVariant={"border"} variant={"icon"} disabled={idx === formik.values.cols.length - 1} onClick={() => arrayHelpers.move(idx, idx + 1)}/>
                                    <SldsButton className={"slds-button_icon-error"} iconCategory={"utility"} iconName={"delete"} iconVariant={"border-filled"} variant={"icon"} onClick={() => arrayHelpers.remove(idx)}/>
                                </SldsButtonGroup>
                                <SldsButton iconCategory={"utility"} iconName={"add"} iconPosition={"left"} onClick={() => arrayHelpers.insert(idx, {})}>Add Column</SldsButton>
                            </React.Fragment>
                            }
                        >
                            <SldsFormElementCompound key={idx} label={"Column"}>
                                <SldsInputField label={"Heading"} name={`cols.${idx}.heading`}>{col.heading}</SldsInputField>
                                <SldsInputField label={"Width"} name={`cols.${idx}.width`} placeholder={"e.g. 2rem"}>{col.width}</SldsInputField>
                                <SldsInputField label={"Sort Property"} name={`cols.${idx}.sortProperty`} placeholder={"e.g. id"}>{col.sortProperty}</SldsInputField>
                                <SldsFormElementCompound label={"CSV Export"}>
                                    <SldsFormElementRow>
                                        <SldsTextareaField className="slds-size_11-of-12 slds-col" label={"CSV Format"} rows={2} name={`cols.${idx}.csvFormat`} placeholder={`e.g. {{data.temperature}}`}>{cell.format}</SldsTextareaField>
                                    </SldsFormElementRow>
                                </SldsFormElementCompound>
                                <SldsFormElementCompound label={"Cell"}>
                                    <SldsFormElementRow>
                                        <SldsTextareaField className="slds-size_11-of-12 slds-col" label={"Format"} rows={2} name={`cols.${idx}.cell.format`} placeholder={`e.g. {{name}}`}>{cell.format}</SldsTextareaField>
                                        <SldsCheckboxField className="slds-size_1-of-12 slds-col slds-grow" label={"Is\u00A0HTML"} name={`cols.${idx}.cell.isHtml`}/>
                                    </SldsFormElementRow>
                                    <SldsInputField label={"Href (optional link)"} name={`cols.${idx}.cell.href`} placeholder={`e.g. /link/to/page`}>{cell.href}</SldsInputField>
                                </SldsFormElementCompound>
                                <hr className="slds-m-vertical--small"/>
                            </SldsFormElementCompound></AccordionPanel>
                    })}
                </Accordion>
                {!readOnly && <SldsFormElement className="slds-m-top--x-small">
                    <SldsFormElementRow>
                        <SldsButton iconCategory={"utility"} iconName={"add"} iconPosition={"left"} onClick={() => arrayHelpers.push({})}>Add Column</SldsButton>
                        <SldsButton variant={"destructive"} iconCategory={"utility"} iconName={"undo"} iconPosition={"left"} onClick={() => onRestoreDefault()}>Restore Default</SldsButton>
                    </SldsFormElementRow>
                </SldsFormElement>}
            </Fragment>
        }}>

        </FieldArray>
    </Form>
}

GenericDataTableConfigForm.propTypes = {
    id: PropTypes.string,
    formik: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onRestoreDefault: PropTypes.func.isRequired,
};