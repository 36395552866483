import React, {useState} from "react";
import {Link, Route, useParams} from "react-router-dom";
import HardwareActivationTable from "./hardwareActivationTable";
import Page from "../common/ui/page";
import Button from "../common/slds/buttons/button";

const HardwareActivationPage = (props) => {
    const routeParams = useParams();
    const [activationCode, setActivationCode] = useState(routeParams.activationCode || "");

    return <Page title={"Hardware Activation"} trail={[]}>
        <div className="slds-p-horizontal--medium">

            <div className="slds-grid">
                <div className="slds-size_6-of-6 slds-m-right--small">
                    <div className="slds-text-heading--large slds-m-top--large slds-m-bottom--small">Activate Hardware</div>
                    <input type={"text"} id="activation-code-input" className="slds-input slds-m-bottom--small"
                           placeholder={"Activation Code"}
                           autoFocus={true}
                           value={activationCode}
                           onChange={(e) => setActivationCode(e.target.value)}
                    />
                    <Link to={"/hardwareActivation/" + activationCode}>
                        <Button>Show Hardware</Button>
                    </Link>
                </div>
            </div>

            <Route exact path={"/hardwareActivation/:activationCode"}>
                <HardwareActivationTable/>
            </Route>
            <Route exact path={"/hardwareActivation"}>
                <div className="slds-text-heading--large slds-m-top--large slds-m-bottom--small">Activate Hardware</div>
                <p>
                    Enter your activation code here. You can find it on the delivery note. Blablup.
                </p>
            </Route>
        </div>
    </Page>;
};

export default HardwareActivationPage;