import {platformConfig} from "./platformConfig";
import {Log} from "./log";


export function flattenObject(ob) {
    var toReturn = {};

    for (var i in ob) {
        if (!ob.hasOwnProperty(i)) continue;

        if ((typeof ob[i]) == 'object' && ob[i] !== null) {
            var flatObject = flattenObject(ob[i]);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;

                toReturn[i + '.' + x] = flatObject[x];
            }
        } else {
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
}

export function backendUrl() {
    let url = `${platformConfig.backendUrl}`;

    if (!platformConfig.backendUrl) {
        Log.Info("platformConfig.backendUrl not set!", process.env.BACKEND_URL)
    }

//let url = `${window.location.origin}/graphql`;
// Hack for development setup
    if (window.location.port === "8888") {
        url = url.replace("8888", "8080")
    }

    return url;
}


export function getQueryStringParams(query) {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
}
