import gql from "graphql-tag";

export const QUERY_APPLICATIONS = gql`
    query($orgId: ID, $page: PaginationInputType, $search: String){
        appInstances(page: $page, orgId: $orgId, search: $search) {
            id
            appId
            name
            type
            organisation {
                id
                name
            }
        }
    }
`;

export const QUERY_APPLICATION = gql`
    query($appId: ID!){
        appInstance(id: $appId) {
            id
            appId
            name
            type
            logo
            organisation {
                id
                name
            }
        }
    }
`;


export const MUTATE_CREATE_APP = gql`
    mutation ($newApp: AppInstanceInput!){
        createApplication(input: $newApp) {
            id
            name
            appId
        }
    }
`;

export const MUTATE_DELETE_APP = gql`
    mutation ($appId: ID!){

        deleteApplication(id: $appId) {
            id
            name
            appId
        }
    }
`;
export const MUTATE_UPDATE_APP = gql`
    mutation ($appId: ID!, $input: AppInstanceInput!){

        updateApplication(id: $appId, input: $input) {
            id
            name
            appId
        }
    }
`;

export const MUTATE_CREATE_DEVICE_TYPE = gql`
    mutation deviceType($name: String!, $input:DeviceTypeInput) {
        createDeviceType(name: $name, input: $input) {
            id
            name
            displayName
            parserCode
            parserEnabled
            configProperties
        }
    }
`;

export const MUTATE_UPDATE_DEVICE_TYPE = gql`
    mutation ($id: ID!, $input: DeviceTypeInput!) {
        updateDeviceType(id: $id, input: $input) {
            id
            name
            displayName
            parserCode
            parserEnabled
            configProperties
        }
    }
`;


export const QUERY_DEVICE_TYPE = gql`
    query deviceType($id: ID!) {
        deviceType(id: $id) {
            id
            name
            displayName
            description
            parserCode
            parserEnabled
            configProperties
            deviceTableConfigRaw
            dataTableConfigRaw
            deviceTraits
            numDevices
        }
    }
`;

export const QUERY_DEVICE_TYPES = gql`
    query ($page: PaginationInputType, $search: String) {
        deviceTypes(page: $page, sort: {field: "id", direction: "DESC"}, search: $search) {
            id
            name
            displayName
            configProperties
        }
    }
`;

export const QUERY_HARDWARE_LIST = gql`
    query ($page: PaginationInputType, $search: String, $idNotIn: [ID!]) {
        devices(page: $page, sort: {field: "id", direction: "DESC"}, search: $search, idNotIn: $idNotIn) {
            id
            addr
            name
            description
            app {
                id
                name
            }
            organisation {
                id
                name
            }
            deviceType {
                id
                displayName
            }
            propertiesRaw
            initialConfigRaw
        }
    }`;

export const MUTATE_UPDATE_DEVICE = gql`
    mutation($id: ID!, $input: DeviceInput!) {
        updateDevice(id: $id, input: $input) {
            id
            name
            description
            addr
            activationCode
            initialConfigRaw
            deviceType {
                id
                displayName
                configProperties
            }
            app {
                id
                name
            }
            organisation {
                id
                name
            }
            comment
            firmwareVersion
        }
    }`;

export const MUTATE_CREATE_DEVICE = gql`
    mutation($input: DeviceInput!) {
        createDevice(input: $input) {
            id
            name
            description
            addr
            activationCode
            initialConfigRaw
            deviceType {
                id
                displayName
                configProperties
            }
            app {
                id
                name
            }
            comment
            firmwareVersion
        }
    }`;

export const QUERY_ACTIVATION_GROUP_LIST = gql`
    query ($page: PaginationInputType, $search: String) {
        getActivationGroupList(page: $page, sort: {field: "id", direction: "DESC"}, search: $search) {
            id
            nr
            createdAt
        }
    }`;

export const MUTATE_CREATE_ACTIVATION_GROUP = gql`
    mutation($input: ActivationGroupInput) {
        createActivationGroup(input: $input) {
            id
        }
    }`;

export const MUTATE_UPDATE_ACTIVATION_GROUP = gql`
    mutation($id: ID!, $input: ActivationGroupInput) {
        updateActivationGroup(id: $id, input: $input) {
            id
        }
    }`;
