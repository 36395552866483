import React from "react";
import Page from "../../common/ui/page";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import {useAuthContext} from "../../common/context/authContext";
import {Link, Route, useRouteMatch} from "react-router-dom";
import Url from "../../common/url";
import DeviceTypeParsedData from "./deviceTypeParsedData";
import PropTypes from "prop-types";
import {Redirect} from "react-router";
import {DeviceTypesNav} from "./deviceTypesNav";

export const QUERY_DEVICE_TYPES = gql`
    query($orgId: ID) {
        deviceTypes(orgId: $orgId) {
            id
            displayName
            dataTableConfigRaw
            deviceTableConfigRaw
        }
    }
`;

DeviceTypesNav.propTypes = {
    deviceTypes: PropTypes.array,
};

export const QUERY_DEVICE_TYPE = gql`
    query deviceType($devTypeId: ID!) {
        deviceType(id: $devTypeId) {
            id
            displayName
            deviceTableConfigRaw
        }
    }
`;

const DeviceDataPage = () => {
    const auth = useAuthContext();
    const match = useRouteMatch("/organisation/deviceData/:devTypeId");
    const devTypeId = match?.params?.devTypeId;

    const noSelectMatch = useRouteMatch({
        path: "/organisation/deviceData",
        exact: true,
    });

    const gqlResult = useQuery(QUERY_DEVICE_TYPES, {
            variables: {
                orgId: auth.organisationId()
            }
        }
    );
    const devTypeResult = useQuery(QUERY_DEVICE_TYPE, {
            skip: !devTypeId,
            variables: {
                devTypeId: devTypeId,
            }
        }
    );
    const devType = devTypeResult.data?.deviceType;
    const deviceTypes = gqlResult?.data?.deviceTypes;

    if (deviceTypes?.length >= 1 && noSelectMatch) {
        return <Redirect to={Url.join(noSelectMatch.url, deviceTypes[0].id)}/>
    }

    return <div className="slds-container--fluid">
        <div className="slds-grid">
            {deviceTypes?.length !== 1 && <div className="slds-col slds-no-flex">
                <DeviceTypesNav deviceTypes={deviceTypes} baseUrl={"/organisation/deviceData"}/>
            </div>}
            <div className="slds-col">
                <Page title={devType?.displayName || "Device Data"} trail={[<Link to={"."}  key={1}>Device Data</Link>]}>
                    <div className="slds-grid">
                        <Route exact path={"/organisation/deviceData"}>
                            <div className="slds-p-around--x-small slds-size--1-of-1">
                                <h2 className="slds-text-heading--medium slds-p-around--medium"
                                    id="entity-header">Welcome</h2>
                                Please select a device type from the list.
                            </div>
                        </Route>
                        <Route path={"/organisation/deviceData/:deviceTypeId/"}>
                            <div className="slds-col slds-size--1-of-1">
                                <DeviceTypeParsedData/>
                            </div>
                        </Route>
                    </div>
                </Page>
            </div>
        </div>
    </div>;
};

export default DeviceDataPage;