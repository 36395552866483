import React, {useState} from "react";
import {Field, Form, Formik} from "formik";
import {FormActions, SldsInputField} from "../common/ui/form/formElements";
import {getQueryStringParams} from "../common/helper";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useAuthContext} from "../common/context/authContext";
import {Button} from "@salesforce/design-system-react";


const LoginPage = (props) => {
    const [loginError, setLoginError] = useState(false);
    const location = useLocation();
    const auth = useAuthContext();
    const history = useHistory();

    return <>
        <div className="slds-grid slds-grid--align-center">
            <div className="slds-p-top--large">
                <div className="slds-p-around--medium">
                    <div className="slds-global-header__logo slds-m-bottom--medium"
                         style={{backgroundImage: "url(/static/img/logos/lobaro.svg)"}}>
                    </div>
                    <p className="slds-text-heading--medium">Sign in to Lobaro Dashboard</p>
                </div>
                <div className="slds-card slds-p-around--medium">
                    {loginError &&
                    <div style={{color: "rgb(194, 57, 52)"}}>
                        Login failed. please try again.
                    </div>
                    }
                    <Formik
                        onSubmit={(values, actions) => {
                            auth.login(values.login, values.password)
                                .then(json => {
                                    const queryStringParams = getQueryStringParams(location.search);
                                    const redirectTo = queryStringParams.r || "/";
                                    history.push(redirectTo);
                                })
                                .catch(err => {
                                    setLoginError(true);
                                    actions.setSubmitting(false);
                                });
                        }}
                        initialValues={{
                            login: "",
                            password: ""
                        }}
                    >
                        <Form>
                            <SldsInputField label={"Username / E-Mail"} name={"login"}/>
                            <SldsInputField type={"password"} label={"Password"} name={"password"}/>
                            <FormActions>
                                <Field render={(formik) => <Button type="submit" className="slds-button--brand"
                                                                   disabled={formik.form.isSubmitting} {...props}>Login</Button>}/>
                                <div className="slds-float_right">
                                    <Link to={"/auth/restore-password"} className={"slds-p-bottom--x-small"}>Forgot Password?</Link>
                                </div>
                            </FormActions>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    </>;
};

export default LoginPage;