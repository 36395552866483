import React, {useContext} from "react";
import {Link, Redirect, Route, Switch} from "react-router-dom";
import Page from "../../common/ui/page";
import {Tab, TabNavigation} from "../../common/ui/tabNavigation";
import ContextRoute from "../../common/RouterContext";
import {Log} from "../../common/log";
import RestApiTab from "./restApiTab";
import {HttpApiTab} from "./httpApiTab";
import Url from "../../common/url";
import {AppContext} from "../../app/appPage";
import {MqttApiTab} from "./mqttApiTab";
import {InputFilterTab} from "./inputFilterTab";


export const IntegrationsPage = (props) => {
    const {match, history} = props;

    let app = useContext(AppContext);

    Log.Debug("IntegrationsPage.props", props);
    Log.Debug("IntegrationsPage.match", match);
    Log.Debug("IntegrationsPage.history", history);
    return <Page
        title={"Integrations"}
        trail={[<Link to={`.`} key={1}>Integrations</Link>]}
    >
        <TabNavigation tabs={[
            <Tab key={"filter"} to={Url.join(match.url, `filter/`)}>Filter</Tab>,
            <Tab key={"rest"} to={Url.join(match.url, `rest/`)}>REST</Tab>,
            <Tab key={"http"} to={Url.join(match.url, `http/`)}>HTTP</Tab>,
            <Tab key={"mqtt"} to={Url.join(match.url, `mqtt/`)}>MQTT</Tab>,
        ].filter((t => t != null))}>
            <Switch>
                <ContextRoute exact strict path={Url.join(match.path, `filter/`)} component={InputFilterTab}/>
                <ContextRoute exact strict path={Url.join(match.path, `rest/`)} component={RestApiTab}/>
                <ContextRoute exact strict path={Url.join(match.path, `http/`)} component={HttpApiTab}/>
                <ContextRoute exact strict path={Url.join(match.path, `mqtt/`)} component={MqttApiTab}/>
                <Route path={`${match.path}`} exact render={() => {
                    //return <div>route not found, path: {match.path} Redirect to: {match.url}tab-0/</div>
                    return <Redirect to={Url.join(match.url, `rest/`)}/>;
                }}/>
                <Route render={(props) => {
                    Log.Debug("Page not found props", props);
                    return <div>Page not found</div>;
                }}/>
            </Switch>
        </TabNavigation></Page>;
};