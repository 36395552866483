import React from "react";
import Page from "../../../app/pages/page";
import {useGraphqlLoadingComponent} from "../../../common/graphql";
import {Link, Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import DeviceSettings from "./deviceSettings";
import DeviceParsedData from "./deviceParsedData";
import DeviceSensorData from "./deviceSensorData";
import * as log from "../../../common/log";
import {Log} from "../../../common/log";
import ContextRoute from "../../../common/RouterContext";
import {Tab, TabNavigation} from "../../../common/ui/tabNavigation";
import {useQuery} from "@apollo/react-hooks";
import {useAuthContext} from "../../../common/context/authContext";
import Url from "../../../common/url";
import DeviceConfig from "./deviceConfig";
import DeviceDownlinkData from "./deviceDownlinkData";
import DeviceOverview from "./deviceOverview";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import {Icon} from "../../../common/slds/icons/icon";
import Tooltip from "../../../common/slds/tooltip/tooltip";
import ReactTooltip from 'react-tooltip';
import ReactDOM from "react-dom";
import {CustomPropTypes} from "../../../common/propTypes/customPropTypes";
import {DeviceDatasourceType, DeviceDatasourceTypeLabel} from "../../../model/device";
import {DataSourceLinkStatusIcon} from "./datasourceLinkStatusIcon";

export const QUERY_DEVICE = gql`
    query device($devId: ID!) {
        device(id: $devId) {
            id
            name
            description
            addr
            parserCode
            parserEnabled
            propertiesRaw
            location {
                lat
                lon
            }
            organisation{
                id
                name
            }
            datasource {
                connected
                type
            }
            deviceType {
                id
                name
                displayName
                parserCode
                parserEnabled
            }
            sensorData(page: {limit: 1, offset: 0}) {
                id
                dataRaw
            }
            # Tokens for API tab
            app {
                id
                name
                apiTokens {
                    id
                    name
                    token
                }
            }
        }
        # For deviceSettings page
        deviceTypes {
            id
            name
            displayName
        }

    }
`;

function AddToBody(props) {
    let bodyNode = document.getElementsByTagName("BODY")[0];
    return ReactDOM.createPortal(
        props.children,
        bodyNode
    );
}

Tooltip.propTypes = {
    children: CustomPropTypes.children,
};



export default function DeviceDetailPage(props) {
    const match = useRouteMatch();
    const auth = useAuthContext();
    const devId = match.params.deviceId;

    const deviceResult = useQuery(QUERY_DEVICE, {
        fetchPolicy: 'cache-first',
        variables: {devId: devId},
    });

    const loading = useGraphqlLoadingComponent(deviceResult);
    if (loading) {
        return loading;
    }

    const {device} = deviceResult.data;

    log.Debug("DeviceDetailPage loading dev id:", match.params.id);

    log.Debug("DeviceDetailPage.deviceResult", deviceResult);
    log.Debug("DeviceDetailPage.props", props);
    log.Debug("DeviceDetailPage.match", match);


    return <Page title={<div>{`${device.name}` || "Device"} <DataSourceLinkStatusIcon datasource={device.datasource}/></div>}
                 trail={[<Link to={`../`} key={1}>Devices</Link>, <Link to={`.`} key={2}>{device.name}</Link>]}
                 info={<div>
                     <ul className="slds-list--horizontal slds-has-dividers_left">
                         <li className="slds-item">{device.deviceType.displayName}</li>
                         <li className="slds-item">{device.addr}</li>
                     </ul>
                     {device.description}
                 </div>}

    >
        <TabNavigation tabs={[
            <Tab key={"overview"} to={Url.join(match.url, 'overview')}>Overview</Tab>,
            <Tab key={"parsed"} to={Url.join(match.url, 'device-data')}>Device Data</Tab>,
            <Tab key={"raw"} to={Url.join(match.url, 'raw-data')}>Uplinks</Tab>,
            <Tab key={"downlinks"} to={Url.join(match.url, 'downlinks')}>Downlinks</Tab>,
            <Tab key={"config"} to={Url.join(match.url, 'config')}>Config</Tab>,
            <Tab key={"settings"} to={Url.join(match.url, 'settings')}>Settings</Tab>,
        ]}>
            <Switch>
                <ContextRoute exact path={Url.join(match.path, `overview`)} component={DeviceOverview}/>
                <ContextRoute exact path={Url.join(match.path, `device-data`)} component={DeviceParsedData}/>
                <ContextRoute exact path={Url.join(match.path, `raw-data`)} component={DeviceSensorData}/>
                <ContextRoute exact path={Url.join(match.path, `downlinks`)} component={DeviceDownlinkData}/>
                <ContextRoute exact path={Url.join(match.path, `settings`)} component={DeviceSettings}/>
                <ContextRoute exact path={Url.join(match.path, `config`)} component={DeviceConfig}/>
                <Route path={`${match.path}`} exact render={() => {
                    //return <div>route not found, path: {match.path} Redirect to: {Path.join(`${match.url}`, "overview")}/</div>
                    return <Redirect to={Url.join(`${match.url}`, "overview")}/>;
                }}/>
                <Route render={(props) => {
                    Log.Error("Page not found props", props);
                    //return <div>route not found, path: {match.path}, url: {match.url}, Redirect to: {Path.join(`${match.url}`, "overview")}/</div>
                    return <Redirect to={Url.join(`${match.url}`, "overview")}/>;
                    //return <div>Page not found</div>
                }}/>
            </Switch>
        </TabNavigation>
    </Page>;
}