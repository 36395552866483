import React, {useContext, useState} from "react";
import {FieldArray, Formik} from "formik";
import {Log} from "../../common/log";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {AppContext} from "../../app/appPage";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {Accordion, AccordionPanel} from "../../common/ui/accordion";
import {CancelButtonField, FormActions, Option, SldsButtonGroup, SldsFormElementCompound, SldsFormElementLabel, SldsFormElementRow, SldsInputField, SldsSelectField, SubmitButtonField} from "../../common/ui/form/formElements";
import {Modal} from "../../common/slds";
import {Form} from "../../common/ui/form/formik";
import moment from "moment";
import {useAuthContext} from "../../common/context/authContext";
import Button from "../../common/slds/buttons/button";
import ScopedNotification from "../../common/slds/scopedNotifications/scopedNotification";
import DescriptionList, {DescriptionListEntry} from "../../common/slds/descriptionList/descriptionList";
import Roles from "../../model/roles";
import {useNotificationContext} from "../../notifications/notificationContext";
import IntegrationFilterLookupField from "../../components/integration/integrationFilterLookupField";

const MUTATION_CREATE_INTEGRATION_HTTP = gql`
    mutation ($appId: ID, $orgId: ID!, $input: IntegrationHttpInput) {
        integrationHttp: createIntegrationHttp(appId: $appId, orgId: $orgId, input: $input){
            id
        }
    }
`;

const MUTATION_UPDATE_INTEGRATION_HTTP = gql`
    mutation ($id: ID!, $input: IntegrationHttpInput!) {
        integrationHttp: updateIntegrationHttp(id: $id input: $input){
            id
        }
    }
`;

const MUTATION_DELETE_INTEGRATION_HTTP = gql`
    mutation ($id: ID!) {
        integrationHttp: deleteIntegrationHttp(id: $id){
            id
        }
    }
`;

const QUERY_INTEGRATION_HTTP = gql`
    query ($appId: ID, $orgId: ID!) {
        integrationHttp: integrationHttpList(appId: $appId, orgId: $orgId){
            id
            appId
            inputFilter {
                id
                name
            }
            method
            url
            header
            responseStatus
            lastMessage
            lastRequest {
                method
                url
                headers
                body
            }
            lastResponse {
                status
                headers
                body
            }
            auth {
                type
                username
                password
                scope
                tokenUrl
            }
        }
    }
`;

export const HttpApiTab = (props) => {
    const app = useContext(AppContext);
    const auth = useAuthContext();
    const notify = useNotificationContext();
    const [modalOpen, setModalOpen] = useState(false);
    const orgId = auth.organisationId();
    const canEdit = auth.hasRole(Roles.ADMIN, Roles.ORG_ADMIN);
    const appId = app?.id;
    const integrationHttpResult = useQuery(QUERY_INTEGRATION_HTTP, {
        variables: {
            "appId": appId,
            "orgId": orgId,
        }
    });

    const [createIntegrationMutation] = useMutation(MUTATION_CREATE_INTEGRATION_HTTP, {
        variables: {
            "appId": appId,
            "orgId": orgId,
        },
        refetchQueries: [{
            query: QUERY_INTEGRATION_HTTP,
            variables: {
                "appId": appId,
                "orgId": orgId,
            },
        }]
    });
    const [updateIntegrationMutation] = useMutation(MUTATION_UPDATE_INTEGRATION_HTTP, {
        variables: {},
        refetchQueries: [{
            query: QUERY_INTEGRATION_HTTP,
            variables: {
                "appId": appId,
                "orgId": orgId,
            },
        }]
    });
    const [deleteIntegrationMutation] = useMutation(MUTATION_DELETE_INTEGRATION_HTTP, {
        variables: {},
        refetchQueries: [{
            query: QUERY_INTEGRATION_HTTP,
            variables: {
                "appId": appId,
                "orgId": orgId,
            },
        }]
    });

    const loading = useGraphqlLoadingComponent(integrationHttpResult);
    if (loading) {
        return loading;
    }

    Log.Debug("integrationHttpResult", integrationHttpResult);
    const {integrationHttp} = integrationHttpResult.data;

    return <div className="slds-m-horizontal--x-small">
        <p className="slds-m-bottom--medium">A HTTP integration sends device data to a remote server via HTTP(S).</p>
        {integrationHttp.length === 0 ? <ScopedNotification theme="light" className="slds-m-bottom--small">No integration created yet!</ScopedNotification> : null}
        <Accordion>
            {integrationHttp.map((integration, i) => {
                Log.Debug("Integration:", integration);
                const lastRequestTime = integration.lastMessage ? moment(integration.lastMessage).format('DD.MM.YYYY HH:mm:ss') : "never";
                return <AccordionPanel expanded={false} key={i} id={i}
                                       summary={`${integration.method} ${integration.url}`}
                                       panelContentActions={`${integration.responseStatus} @ ${lastRequestTime}`}
                >
                    <Formik
                        initialValues={{
                            ...integration,
                        }}
                        initialStatus={{
                            readOnly: true,
                            canEdit: canEdit,
                        }}
                        onSubmit={(values, actions) => {
                            Log.Debug("HttpApiTab.submit", values);
                            return updateIntegrationMutation({
                                variables: {
                                    id: integration.id,
                                    input: {
                                        method: values.method,
                                        url: values.url,
                                        header: values.header,
                                        inputFilterId: values.inputFilter?.id || 0, // set 0 to remove, null would be just ignored
                                        auth: {
                                            type: values.auth.type,
                                            username: values.auth.username,
                                            password: values.auth.password,
                                            tokenUrl: values.auth.tokenUrl,
                                            scope: values.auth.scope,
                                        },
                                    }
                                }
                            }).then((res) => {
                                notify.success("Updated HTTP integration.");
                            }).catch((err) => {
                                notify.error("Failed to update HTTP integration.", err);
                            });
                        }}
                    >{(formik) => {
                        return <Form>
                            <IntegrationFilterLookupField name={"inputFilter"} orgId={orgId}/>
                            <SldsSelectField name={"method"} label={"HTTP Method"} required={true}>
                                <Option value={""} label={"Please select"}/>
                                <Option value={"post"} label={"POST"}/>
                                <Option value={"get"} label={"GET"}/>
                                <Option value={"put"} label={"PUT"}/>
                                <Option value={"patch"} label={"PATCH"}/>
                                <Option value={"delete"} label={"DELETE"}/>
                            </SldsSelectField>
                            <SldsInputField name={"url"} label={"Target URL"} placeholder={"https://example.com/api/data"} required={true}/>
                            <SldsFormElementCompound>
                                <SldsFormElementLabel>Authentication</SldsFormElementLabel>
                                <SldsSelectField name={"auth.type"} label={"Method"} options={
                                    [
                                        {value: "", label: "None"},
                                        {value: "basicauth", label: "Basic Auth"},
                                        {value: "bearer", label: "Bearer Token"},
                                        {value: "oauth", label: "OAuth"},
                                    ]
                                }>
                                </SldsSelectField>
                                {
                                    formik.values?.auth?.type == 'basicauth' ? <div>
                                        <SldsInputField label={"Username"} name={"auth.username"}></SldsInputField>
                                        <SldsInputField label={"Password"} type={"password"} name={"auth.password"}></SldsInputField>
                                    </div> : null
                                }
                                {
                                    formik.values?.auth?.type == 'bearer' ? <div>
                                        <SldsInputField label={"Token"} name={"auth.password"}></SldsInputField>
                                    </div> : null
                                }
                                {
                                    formik.values?.auth?.type == 'oauth' ? <div>
                                        <SldsInputField label={"Token URL"} name={"auth.tokenUrl"}></SldsInputField>
                                        <SldsInputField label={"ClientId"} name={"auth.username"}></SldsInputField>
                                        <SldsInputField label={"Secret"} name={"auth.password"}></SldsInputField>
                                        <SldsInputField label={"Scope"} name={"auth.scope"}></SldsInputField>
                                    </div> : null
                                }
                            </SldsFormElementCompound>
                            <FieldArray name={"header"}>{
                                (arrayHelpers => {
                                    const {form} = arrayHelpers;
                                    const values = form.values?.header;
                                    return <>
                                        <SldsFormElementCompound>
                                            <SldsFormElementLabel>Headers</SldsFormElementLabel>
                                            {
                                                values?.map((h, idx) => {
                                                    return <SldsFormElementRow key={idx}>
                                                        <SldsInputField key={idx} name={`header.${idx}`} className="slds-grow"/>
                                                        {!form.status.readOnly ? <SldsButtonGroup className="slds-shrink slds-align-bottom">
                                                            <Button iconName={"arrowup"} iconSize={"small"} disabled={idx === 0} onClick={() => arrayHelpers.move(idx, idx - 1)}/>
                                                            <Button iconName={"arrowdown"} iconSize={"small"} disabled={idx === values.length - 1} onClick={() => arrayHelpers.move(idx, idx + 1)}/>
                                                            <Button className={"slds-button_icon-error"} iconName={"delete"} iconSize={"small"} onClick={() => arrayHelpers.remove(idx)}/>
                                                        </SldsButtonGroup> : null}
                                                    </SldsFormElementRow>;
                                                })
                                            }
                                            <SldsFormElementRow>
                                                {!form.status.readOnly ?
                                                    <Button iconName={"add"} onClick={() => arrayHelpers.push("")}>Add Header</Button>
                                                    : null}
                                            </SldsFormElementRow>
                                        </SldsFormElementCompound>
                                    </>;
                                })
                            }</FieldArray>

                            <FormActions>
                                <SubmitButtonField>Save</SubmitButtonField>
                                <CancelButtonField>Cancel</CancelButtonField>
                                <Button variant={"destructive"} iconName={"delete"} onClick={() => {
                                    if (window.confirm("Delete integration?")) {
                                        deleteIntegrationMutation({variables: {id: integration.id}})
                                            .then(() => {
                                                notify.success("Deleted HTTP integration.");
                                            })
                                            .catch((err) => {
                                                notify.error("Failed to delete HTTP integration", err);
                                            });
                                    }
                                }}>Delete</Button>
                            </FormActions>
                        </Form>;
                    }}</Formik>

                    <div className="slds-text-heading--medium slds-m-top--medium slds-m-bottom--xx-small">Last Request / Response</div>
                    <DescriptionList>
                        <DescriptionListEntry label={"Time"} description={lastRequestTime}/>
                        <DescriptionListEntry label={"Method"} description={integration.lastRequest?.method}/>
                        <DescriptionListEntry label={"Url"} description={integration.lastRequest?.url}/>
                        <DescriptionListEntry label={"Status"} description={integration.lastResponse?.status}/>
                    </DescriptionList>
                    <div className="slds-text-heading--small slds-m-top--small slds-m-bottom--xx-small">Request Headers</div>
                    <div className="slds-scrollable_x">
                        {integration.lastRequest?.headers?.map((h, i) => {
                            return <div key={i} className="slds-text-font_monospace">{h}</div>;
                        })}
                    </div>
                    <div className="slds-text-heading--small slds-m-top--small slds-m-bottom--xx-small">Request Body</div>
                    <textarea className="slds-size_1-of-1 slds-text-font_monospace" readOnly rows={6} defaultValue={integration.lastRequest?.body}/>
                    <div className="slds-text-heading--small slds-m-top--small slds-m-bottom--xx-small">Response Headers</div>
                    <div className="slds-scrollable_x">
                        {integration.lastResponse?.headers?.map((h, i) => {
                            return <div key={i} className="slds-text-font_monospace">{h}</div>;
                        })}
                    </div>
                    <div className="slds-text-heading--small slds-m-top--small slds-m-bottom--xx-small">Response Body</div>
                    <textarea className="slds-size_1-of-1 slds-text-font_monospace" readOnly rows={6} defaultValue={integration.lastResponse?.body}/>
                </AccordionPanel>;
            })}
        </Accordion>

        {canEdit ?
            <Button iconName={"add"} iconCategory={"utility"} onClick={() => setModalOpen(true)}>Add Integration</Button>
            : null}

        <Modal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)} header={"Create HTTP Integration"}>
            <Formik
                initialValues={{
                    method: "post"
                }}
                onSubmit={(values, actions) => {
                    Log.Debug("HttpApiTab.submit", values);
                    createIntegrationMutation({
                        variables: {
                            input: {
                                method: values.method,
                                url: values.url,
                            }
                        }
                    }).then(() => {
                        notify.success("Created HTTP integration.");
                        setModalOpen(false);
                    }).catch((err) => {
                        notify.error("Failed to create HTTP integration.", err);
                    }).finally(() => {
                        actions.setSubmitting(false);
                    });

                }}
            >
                <Form>
                    <SldsSelectField name={"method"} label={"HTTP Method"} required={true}>
                        <Option value={""} label={"Please select"}/>
                        <Option value={"post"} label={"POST"}/>
                        <Option value={"get"} label={"GET"}/>
                        <Option value={"put"} label={"PUT"}/>
                        <Option value={"patch"} label={"PATCH"}/>
                        <Option value={"delete"} label={"DELETE"}/>
                    </SldsSelectField>
                    <SldsInputField name={"url"} label={"Target URL"} placeholder={"https://example.com/api/data"} required={true} autoFocus={true}/>

                    <FormActions>
                        <SubmitButtonField>Save</SubmitButtonField>
                        <CancelButtonField onClick={() => setModalOpen(false)}>Cancel</CancelButtonField>
                    </FormActions>
                </Form>

            </Formik>
        </Modal>
    </div>;
};