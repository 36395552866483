import React from "react";
import {Link} from "react-router-dom";

const ToolsOverview = (props) => {
    const tools = [
        {
            "name": "wMBus Parser",
            "link": "/wmbus",
            "description": "Parser for the wMBus protocol"
        },
        {
            "name": "Hardware Activation",
            "link": "/hardwareActivation",
            "description": "Activate your hardware using the Activation Code"
        }
    ];
    return <div className="slds-m-around--medium">
        <ul className="slds-grid slds-wrap slds-grid_pull-padded-xx-small">
            {tools.map((tool, i) => <li key={i}
                className="slds-size--1-of-1 slds-medium-size_1-of-3 slds-large-size--1-of-4 slds-p-horizontal--xx-small slds-m-bottom--x-small">
                <article className="slds-box slds-box--x-small">
                    <div className="slds-tile__detail slds-p-bottom--large">
                        <p className="slds-truncate">{tool.name}</p>
                        <p className="slds-text-heading_medium">
                            <Link to={tool.link}>{tool.name}</Link>
                        </p>
                        <p className="slds-truncate">
                            {tool.description}
                        </p>
                    </div>
                </article>
            </li>)
            }
        </ul>
    </div>;
};

export default ToolsOverview;