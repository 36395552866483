import * as React from "react";
import {useState} from "react";
import Page from "../../app/pages/page";
import {Link, useRouteMatch} from "react-router-dom";
import {ButtonGroup} from "@salesforce/design-system-react";
import {CancelButtonField, FormActions, SldsInputField, SubmitButtonField} from "../../common/ui/form/formElements";
import {Modal} from "../../common/slds";
import {Formik} from "formik";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {MUTATE_CREATE_DEVICE_TYPE, QUERY_DEVICE_TYPES} from "../queries";
import {Log} from "../../common/log";
import {Form} from "../../common/ui/form/formik";
import {usePagination} from "../../common/hooks/usePagination";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import DataTableColumn from "../../common/ui/data-table/column";
import CustomDataTableCell from "../../common/ui/data-table/customCell";
import Url from "../../common/url";
import {useHistory} from "react-router";
import {useSearch} from "../../common/hooks/useSearch";
import Button from "../../common/slds/buttons/button";


export function DeviceTypesPage(props) {
    const [showNewDeviceTypeModal, setShowNewDeviceTypeModal] = useState(false);
    const match = useRouteMatch();
    const search = useSearch("");
    const history = useHistory();
    const page = usePagination(25);
    const deviceTypesResult = useQuery(QUERY_DEVICE_TYPES, {
        variables: {
            page: page.getGraphqlPageInput(),
            search: search.getGraphqlSearchInput()
        }
    });
    const [createDeviceType] = useMutation(MUTATE_CREATE_DEVICE_TYPE);

    return <Page
        trail={[<Link to="/device-types" key={1}>Device Types</Link>]}
        title={"Device Types"}
        actions={<ButtonGroup><Button iconName={"add"} onClick={() => setShowNewDeviceTypeModal(true)}>Create Device
            Type</Button></ButtonGroup>}
    >
        <GenericDataTable id={"device-types-table"} items={deviceTypesResult.data && deviceTypesResult.data.deviceTypes || []} tableConfigDefault={{}}
                          gqlResult={deviceTypesResult} page={page} search={search}>
            <DataTableColumn label="Name" property="name">
                <CustomDataTableCell render={(props) => (
                    <Link
                        to={Url.join(match.url, `/${props.item.id}`)}>{props.item.displayName ? props.item.displayName : "- no name -"}</Link>
                )}/>
            </DataTableColumn>
        </GenericDataTable>;

        <Modal isOpen={showNewDeviceTypeModal} onRequestClose={() => setShowNewDeviceTypeModal(false)}>
            <Formik
                initialValues={{}}
                onSubmit={(values, actions) => {
                    createDeviceType({
                        variables: {
                            name: values.name,
                            input: {
                                displayName: values.displayName,
                            }
                        }
                    }).then((result) => {
                        history.push("/configuration/device-types/" + result.data.createDeviceType.id)
                    }, (e) => {
                        actions.setFieldError("global", e);
                        Log.Error("Failed to create Device Type", e);
                    }).finally(() => {
                        actions.setSubmitting(false);
                    })
                }}>
                <Form>
                    <SldsInputField name={"displayName"} label={"Display Name"} placeholder={"Device Type Name"}
                                    required={true}/>
                    <SldsInputField name={"name"} label={"Type Name"} placeholder={"device-type-name"} required={true}
                                    validate={(value) => /^[a-zA-Z0-9]+[a-zA-Z0-9\-]*[a-zA-Z0-9]+$/.test(value) ? null : "Only use  [0-9, a-z, A-Z] and '-' as separator"}
                    />
                    <FormActions>
                        <SubmitButtonField>Create</SubmitButtonField>
                        <CancelButtonField onClick={() => setShowNewDeviceTypeModal(false)}>Cancel</CancelButtonField>
                    </FormActions>
                </Form>
            </Formik>
        </Modal>
    </Page>
}