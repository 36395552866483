import React from "react";
import VerticalNav, {VerticalNavEntries, VerticalNavEntry, VerticalNavSection, VerticalNavTitle} from "../../common/slds/verticalNavigation/verticalNavigation";
import {useRouteMatch} from "react-router";
import Url from "../../common/url";
import {Redirect, Route, Switch} from "react-router-dom";
import NotFound from "../../common/ui/notFound";
import WmbusConfigPage from "./wmbus/WmbusConfigPage";
import OrganisationSettingsPage from "./settings/OrganisationSettingsPage";


export default function OrgConfigPage(props) {
    const match = useRouteMatch();

    return <div className="slds-container--fluid">
        <div className="slds-grid">
            <div className="slds-col slds-no-flex">
                <VerticalNav>
                    <VerticalNavSection>
                        <VerticalNavTitle>Organisation</VerticalNavTitle>
                    <VerticalNavEntries>
                        <VerticalNavEntry to={Url.join(match.url, "/settings")}>Settings</VerticalNavEntry>
                        <VerticalNavEntry to={Url.join(match.url, "/wmbus")}>wMbus</VerticalNavEntry>
                    </VerticalNavEntries>
                    </VerticalNavSection>
                </VerticalNav>
            </div>
            <div className="slds-col">
                <Switch>
                    <Route exact path={Url.join(match.path, "/settings")} component={OrganisationSettingsPage}/>,
                    <Route exact path={Url.join(match.path, "/wmbus")} component={WmbusConfigPage}/>,

                    <Route exact path={Url.join(match.path, "/")}
                           render={() => <Redirect to={Url.join(match.url, "/settings")}/>}/>
                    <Route render={() => <NotFound/>}/>
                </Switch>
            </div>
        </div>
    </div>
}