import * as React from "react";
import {useContext} from "react";
import {useGraphqlLoadingComponent} from "../../../common/graphql";
import GenericDataTable from "../../../common/ui/genericDataTable/genericDataTable";
import gql from "graphql-tag";
import * as log from "../../../common/log";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {RouterContext} from "../../../common/RouterContext";
import {useAuthContext} from "../../../common/context/authContext";
import {usePagination} from "../../../common/hooks/usePagination";
import {useSort} from "../../../common/hooks/useSort";
import {useCsvExport} from "../../../common/hooks/useCsvExport";
import {MUTATE_UPDATE_DEVICE_TYPE} from "../../../configuration/queries";
import {NotifyUser} from "../../../common/userNotification";
import {DEFAULT_DATA_TABLE_CONFIG} from "../../../configuration/deviceTypes/deviceTypesDetailPage";
import {ParsedDataDetailComponent} from "../../../domain/traits/traits";
import {Log} from "../../../common/log";

const QUERY_PARSED_DATA_DEVICE = gql`
    query device($devId: ID!, $page:PaginationInputType, $filter:[FilterInputType!], $sort: SortInputType) {
        parsedData(devId: $devId, page: $page, filter: $filter, sort: $sort) {
            id
            createdAt
            time
            type
            dataRaw
            datasource {
                id
                name
            }
        }
        device(id: $devId) {
            addr
            id
            name
            description
            configRaw
            propertiesRaw
            deviceType {
                id
                name
                dataTableConfigRaw
                deviceTraits
            }
            app {
                id
                configRaw
            }
        }
    }
`;


export default function DeviceParsedData(props) {
    const auth = useAuthContext();
    const sort = useSort();
    const {match} = useContext(RouterContext);
    const devId = match.params.deviceId;

    const pageSize = 100;

    const page = usePagination(pageSize);

    const queryResult = useQuery(QUERY_PARSED_DATA_DEVICE, {
        fetchPolicy: "cache-and-network",
        pollInterval: page.currentPage() === 1 ? 5000 : 0,
        variables: {
            devId: devId,
            page: page.getGraphqlPageInput(),
            sort: sort.getGraphqlSortInput()
        }
    });
    const device = queryResult?.data?.device;
    const deviceType = device?.deviceType;
    //const deviceTypeTableConfig = deviceType?.dataTableConfigRaw;

    // Takes parsed data and unmarshal json fields
    const parsedDataMapper = (d) => {
        return {
            ...d,
            data: JSON.parse(d.dataRaw),
            //deviceProperties: JSON.parse(queryResult.data.device.propertiesRaw || "{}") || {},
            dataRaw: undefined, // Hide dataRaw
        };
    };

    const csvExport = useCsvExport(QUERY_PARSED_DATA_DEVICE, {
        variables: {
            devId: devId,
            sort: sort.getGraphqlSortInput()
        },
        dataExtractor: d => d.data.parsedData.map(parsedDataMapper)
    });

    const [updateDeviceType] = useMutation(MUTATE_UPDATE_DEVICE_TYPE, {
        variables: {id: deviceType?.id},
    });

    const loading = useGraphqlLoadingComponent(queryResult);
    if (loading) {
        return loading;
    }


    const {data} = queryResult;
    log.Debug("DeviceData result = ", queryResult);


    let prefixCols = [{
        heading: "Received",
        csvFormat: "{{date createdAt}}",
        cell: {
            format: "{{date createdAt}}",
        }
    },
    ];


    let tableConfig = DEFAULT_DATA_TABLE_CONFIG;
    if (deviceType.dataTableConfigRaw) {
        tableConfig = JSON.parse(deviceType.dataTableConfigRaw);
    } else {
        prefixCols.push({
            "heading": "Time",
            "csvFormat": "{{date time}}",
            "cell": {
                "format": "{{date time}}"
            }
        });
        // TODO: add optional datasource.name column even when dataTableConfigRaw exists
        prefixCols.push({
            "heading": "Datasource",
            "csvFormat": "{{datasource.name}}",
            "cell": {
                "format": "{{datasource.name}}"
            }
        });
    }

    return <GenericDataTable
        id={"parsed-data-table"}
        tableConfigDefault={tableConfig}
        gqlResult={queryResult}
        prefixCols={prefixCols}
        handleTableConfigSave={(values) => {
            return updateDeviceType({
                variables: {
                    input: {
                        dataTableConfig: JSON.stringify(values),
                    }
                }
            }).catch((err) => {
                NotifyUser.Error("Failed to save Table Config", err);
            });
        }}
        items={queryResult.data.parsedData.map(parsedDataMapper)}
        //selectedItem={data.parsedData[0]}
        renderDetails={(item) => <ParsedDataDetailComponent traits={deviceType.deviceTraits} data={item}/>}
        hideDetails={false}
        page={page}
        sort={sort}
        csvExport={csvExport}
    />;

}