import {useAuthContext} from "../common/context/authContext";
import AppOverview from "./appOverview";
import React from "react";
import ToolsOverview from "./toolsOverview";
import DevicesOverview from "./devicesOverview";
import {Accordion, AccordionPanel} from "../common/ui/accordion";

const UserHomePage = (props) => {
    const auth = useAuthContext();

    return <Accordion>
        {auth.isLoggedIn() && <AppOverview/>}
        {auth.isLoggedIn() && <AccordionPanel id={"devices"} summary={"Devices"} expanded={true}>
            <DevicesOverview/>
        </AccordionPanel>}
        <AccordionPanel id={"tools"} summary={"Tools"} expanded={true}>
            <ToolsOverview/>
        </AccordionPanel>
    </Accordion>;
};
export default UserHomePage