import React from "react";
import PropTypes from "prop-types";
import ErrorBoundary from '../../common/ui/errorBoundary';
import Spinner from '../../common/slds/spinner/spinner';
import Box from '../../common/ui/box';
import {SldsCheckbox} from '../../common/ui/form/formElements';
import * as log from "../../common/log";
import {Log} from "../../common/log";
import _ from "underscore";
import Card, {CardBody, CardHeader, CardHeaderTitle} from "../../common/slds/cards/card";
import Tile from "../../common/slds/tiles/tile";
import Media, {MediaBody, MediaFigure} from "../../common/slds/mediaObjects/media";
import {Icon} from "../../common/slds/icons/icon";
import Tooltip from "../../common/slds/tooltip/tooltip";
import DataTable, {Col, Row, TableBody, TableHead} from "../../common/slds/dataTable";
import moment from "moment";

const WmbusHeaderTable = (props) => {
    const wmbusHeader = [];
    const wmbus = props.mbus || {};
    const header = wmbus?.Header || {};

    if (!_.isEmpty(wmbus)) {
        if (header) {
            wmbusHeader.push(
                /*{
                     name: "EncryptedBlocks",
                     value: `${header.EncryptedBlocks} (${header.EncryptedBlocks * 32} bytes)`,
                 },
                 {
                     name: "HopCount",
                     value: `${header.HopCount}`,
                 },
                 {
                     name: "IsAccessible",
                     value: `${header.IsAccessible ? "true" : "false"}`,
                 },
                 {
                     name: "IsBidirectionalMode",
                     value: `${header.IsBidirectionalMode ? "true" : "false"}`,
                 },
                 {
                     name: "IsSynchronous",
                     value: `${header.IsSynchronous ? "true" : "false"}`,
                 },
                 {
                     name: "ReservedBit",
                     value: `${header.ReservedBit ? "true" : "false"}`,
                 },
                 {
                     name: "TelegramType",
                     value: `${header.TelegramType}`,
                 },*/
            );
        }
    }


    // Because react ...
    wmbusHeader.forEach((h, i) => {
        h.id = `${i}`;
    });

    let encryptedTelegram = !!header?.EncryptionMode;
    let isEncrypted = wmbus.Body?.IsEncrypted;


    let statusText = `${header.EncryptionModeString}`;
    if (encryptedTelegram) {
        statusText = `Encrypted ${statusText} - ${header.EncryptedBlocks} (${header.EncryptedBlocks * 32} bytes)`;
    }

    let iconName = "preview";
    if (isEncrypted) {
        iconName = "lock";
    } else if (encryptedTelegram) {
        iconName = "unlock";
    }

    let statusIcon = <Tooltip position="absolute" top={"-45px"} left={"-17px"}
                              content={<div className="slds-nowrap">{statusText}</div>}>
        <Icon name={iconName} size={"xx-small"}/>
    </Tooltip>;

    return <Card bordered={true}>
        <CardHeader>
            <CardHeaderTitle>
                ID: <span style={{fontWeight: "bold"}}>{wmbus.IdString}</span> <span className="slds-text-body--regular">(v{wmbus.Version})</span>
                <span className="slds-m-left--xx-small">{statusIcon}</span>
            </CardHeaderTitle>
        </CardHeader>
        <CardBody inner={true}>
            <Tile>
                <Media>
                    <MediaFigure>
                        <Icon category={"standard"} name={"service_report"}/>
                    </MediaFigure>
                    <MediaBody>
                        {!_.isEmpty(wmbus) && <>
                            <div><span className="slds-text-title_bold">{wmbus.MFieldCodeString}</span> - {wmbus.MFieldLongString}</div>
                            <div className="slds-grid">
                                <div className="slds-col slds-no-flex slds-m-right--medium">
                                    <div><span className="slds-text-title_bold">{wmbus.DeviceString}</span> ({wmbus.Device})</div>
                                </div>
                            </div>
                            <ul className="slds-list_horizontal slds-has-dividers_left slds-m-top--x-small">
                                <li className="slds-item">C-Field: {wmbus.CFieldString}</li>
                                <li className="slds-item">CI-Field: {`${wmbus.CiField} (${wmbus.HeaderKnown ? "Parsable" : "Unknown"})`}</li>
                                {header.AccessNumber !== undefined ? <li className="slds-item">AccessNumber: {header.AccessNumber}</li> : null}
                            </ul>
                        </>}
                    </MediaBody>
                </Media>


            </Tile>
        </CardBody>
    </Card>;
};

class WmbusDataRacordsTable extends React.Component {

    state = {};

    render() {
        const wmbusFields = [];
        let {mbus, parseError} = this.props;
        let {showAll} = this.state;

        if (!mbus && parseError) {
            return <Box className="slds-m-top--small"><p>Parse Error: {parseError}</p></Box>;
        }

        if (!mbus) {
            return <Spinner type="inlined-container"/>;
        }

        let body = mbus.Body;

        if (!body) {
            return <div>No Data</div>;
        }

        //Log.Debug("props", props);
        log.Debug("mbus", mbus);
        //Log.Debug("body", body);


        if (body.DataRecords && body.DataRecords.length > 0) {
            body.DataRecords.forEach((d, i) => {
                if (!showAll && d.DifDataFormat === "special function") {
                    return;
                }
                wmbusFields.push({
                    id: `${i}`,
                    ...d,
                });
            });
        }

        var noDataMsg = null;
        if (body.IsEncrypted) {
            noDataMsg = "Payload encrypted";
        } else if (!mbus.PayloadKnown) {
            let ciNum = parseInt(mbus.CiField, 16);
            if (ciNum >= 0xA0 && ciNum <= 0xB7) {
                noDataMsg = "Payload format unknown (CiField A1 - B7 are manufacturer specific)";
            } else {
                noDataMsg = "Payload format unknown";
            }
        }

        return <div>
            <SldsCheckbox inlineLabel={"Show All"} className="slds-m-bottom--small slds-m-left--small"
                          tooltip={"Also show technical fields"}
                          field={{
                              value: showAll,
                              onClick: (e) => {
                                  this.setState({showAll: e.target.checked});
                              },
                              onChange: (e) => {
                                  this.setState({showAll: e.target.checked});
                              }
                          }}/>


            <DataTable fixedLayout={false} items={wmbusFields}>
                <TableHead>
                    <Col header>Description</Col>
                    <Col header>Value</Col>
                    <Col header>Tariff</Col>
                    <Col header width={"100%"}>Storage No. </Col>
                    {/* <HeaderCol>Format</HeaderCol>*/}
                </TableHead>
                <TableBody>
                    {wmbusFields.map((it, i) => {
                        let unit = it.VifUnit;
                        // Not needed with latest wmbus parser
                        if (unit === "-") {
                            unit = "";
                        }
                        unit = unit.replace("^3", "³");
                        unit = unit.replace("^2", "²");


                        let func = it.DifFunctionString;
                        if (func === "Current Value") {
                            func = "";
                        }

                        let value = it.ValueString;
                        const description = it.VifQuantity;

                        // seconds
                        if (unit === "s") {
                            value = moment.duration(it.ValueScaled, 'seconds').humanize(false);
                            unit = "";
                        }

                        // Time point (date) is old and was changed to Date
                        if (description === "Time point (date)" || description === "Date") {
                            value = moment(value).format("DD.MM.YYYY");
                        }
                        if (description === "Point in Time") {
                            value = moment.unix(it.Value).format("DD.MM.YYYY HH:mm:ss");
                        }

                        // Time point (date & time) is old and was changed to Date
                        if (description === "Time point (date & time)" || description === "Time & Date") {
                            value = moment(value).format("DD.MM.YYYY HH:mm:ss");
                        }


                        return <Row key={i}>
                            <Col noStripes>{description} {func && `(${func})`}</Col>
                            <Col noStripes>{value} {unit}{it.VifEDescription ? ` (${it.VifEDescription})` : ""}</Col>
                            <Col noStripes>{it.Tariff || "0"}</Col>
                            <Col noStripes>{it.StorageNo || "0"}</Col>
                            {/* <Col>{it.DifDataFormat}</Col>*/}
                        </Row>;
                    })}
                </TableBody>
            </DataTable>
            {noDataMsg ?
                <Box className="slds-m-top--small"><p>{noDataMsg}</p></Box> : null}
            {parseError ?
                <Box className="slds-m-top--small"><p>Parse Error: {parseError}</p></Box> : null}
        </div>;
    }
}

WmbusDataRacordsTable.propTypes = {
    mbus: PropTypes.object,
    parseError: PropTypes.string,
};

function WmbusTelegramDetails(props) {
    let {parseError, mbusJson} = props;
    const mbus = mbusJson;

    if (!parseError) {
        // The parse error could be inside the mbus data
        parseError = mbus.parseError;
    }

    /*
    if (parseError) {
        return <Box className="slds-col slds-size--1-of-1 slds-m-top--small"><p>Parse Error: {parseError}</p></Box>
    }*/


    Log.Debug("WmbusTelegramDetails.mbus", mbus);

    if (_.isEmpty(mbus)) {
        return <Box className="slds-col slds-size--1-of-1 slds-m-top--small"><p>No wMbus data</p></Box>;
    }

    return <div className="slds-grid slds-gutters_direct slds-wrap">
        <div className="slds-col slds-size--1-of-1 slds-p-bottom--x-small">
            <ErrorBoundary>
                <WmbusHeaderTable mbus={mbus}/>
            </ErrorBoundary>
        </div>

        <div className="slds-col slds-size--1-of-1">
            <ErrorBoundary>
                <Card bordered={true}>
                    <CardHeader>
                        <CardHeaderTitle>wMbus Data</CardHeaderTitle>
                    </CardHeader>
                    <WmbusDataRacordsTable mbus={mbus} parseError={parseError}/>
                </Card>
            </ErrorBoundary>
        </div>
    </div>;
}

WmbusTelegramDetails.propTypes = {
    onClose: PropTypes.func,
    // String or object
    mbusJson: PropTypes.any,
    parseError: PropTypes.string,
};

export default WmbusTelegramDetails;

