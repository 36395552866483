import React from "react";
import LoginPage from "./loginPage";
import MyAccountPage from "./myAccountPage";
import {Route} from "react-router-dom";
import RegistrationPage from "./registrationPage";
import ChangePasswordPage from "./changePasswordPage";
import RestorePasswordPage from "./RestorePasswordPage";

const AuthPage = (props) => {
    return <>
        <Route path={"/auth/login"} component={LoginPage}/>
        <Route path={"/auth/register"} component={RegistrationPage}/>
        <Route path={"/auth/restore-password"} component={RestorePasswordPage}/>
        <Route path={"/auth/my-account"} component={MyAccountPage}/>
        <Route path={"/auth/changePassword/:userId"} component={ChangePasswordPage}/>
    </>;
};

export default AuthPage;