import * as slds from "@salesforce/design-system-react";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useAuthContext} from "../common/context/authContext";


const AppLauncher = (props) => {
    const {moduleName} = props;
    const auth = useAuthContext();

    useEffect(() => {
        let pageTitle = moduleName;
        if(moduleName !== 'IoT Platform') {
            pageTitle += ' - IoT Platform';
        }
       document.title = pageTitle;
    });

    // Generate content for the app launcher
    let applications = [];
    if (auth.isLoggedIn) {
        applications.push(<slds.AppLauncherTile
            key="iot-apps"
            title="IoT Applications"
            iconText="APP"
            description="Applications to fetch, process and provide data from IoT Devices"
            href={"#/"}
        />)
    }

    applications.push(<slds.AppLauncherTile
        key="wmbus-api"
        title="wMBus Parser API"
        iconText="WM"
        description="Parse and decrypt wireless MBus telegrams from any metering device"
        href={"#/wmbus"} // TODO: build a AppLauncherTile that supports react links
    />);

    if (auth.hasRole("admin")) {
        applications.push(<slds.AppLauncherTile
            key="configuration"
            title="Configuration"
            iconText="CFG"
            description="Configuration page for admins"
            href={"#/configuration/applications"} // TODO: build a AppLauncherTile that supports react links
        />);
    }

    return <slds.AppLauncher
        id="app-launcher-trigger"
        title={"App Launcher"}
        triggerName={moduleName}
        onSearch={() => {
            console.log('Search term:', event.target.value);
        }}
    >
        <slds.AppLauncherExpandableSection title="Applications">
            {applications}
        </slds.AppLauncherExpandableSection>
    </slds.AppLauncher>
};

AppLauncher.propTypes = {
    moduleName: PropTypes.string.isRequired,
};

export default AppLauncher