import Page from "../../common/ui/page";
import {Link, useHistory} from "react-router-dom";
import * as React from "react";
import {useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import DataTableColumn from "../../common/ui/data-table/column";
import CustomDataTableCell from "../../common/ui/data-table/customCell";
import moment from "moment";
import gql from "graphql-tag";
import {usePagination} from "../../common/hooks/usePagination";
import {useSearch} from "../../common/hooks/useSearch";
import AddUserDialog from "./addUserDialog";
import {ButtonGroup} from "@salesforce/design-system-react";
import Button from "../../common/slds/buttons/button";

const UsersListPage = (props) => {

    const page = usePagination();
    const search = useSearch();
    const history = useHistory();

    const usersListResult = useQuery(gql`
        query($page: PaginationInputType, $search: String) {
            getUserList(page: $page, sort: {field: "createdAt", direction: "DESC"}, search: $search) {
                id
                login
                organisation {
                    id
                    name
                }
                createdAt
                email
            }
        }
    `, {
        variables: {
            page: page.getGraphqlPageInput(),
            search: search.getGraphqlSearchInput()
        }
    });

    const [showNewUserModal, setShowNewUserModal] = useState(false);

    return <Page
        trail={[<Link to="/configuration/users" key={1}>Users</Link>]}
        title={"Users"}
        actions={<ButtonGroup><Button iconName={"add"} onClick={() => setShowNewUserModal(true)}>Create
            User</Button></ButtonGroup>}
    >
        <AddUserDialog isOpen={showNewUserModal} onRequestClose={() => setShowNewUserModal(false)} onAdded={(user) => {
            history.push(`/configuration/users/${user.id}`);
        }}/>
        <GenericDataTable id={"user-table"} items={usersListResult.data && usersListResult.data.getUserList} tableConfigDefault={{}} gqlResult={usersListResult}
                          page={page} search={search}>
            <DataTableColumn label="ID" property={"id"}>
                <CustomDataTableCell key="id" render={(props) => (
                    <Link to={`./users/${props.item.id}`}>{props.item.id}</Link>
                )}/>
            </DataTableColumn>
            <DataTableColumn label="Login" property="login" title={"login"}>
                <CustomDataTableCell key="login" render={(props) => (
                    <Link to={`./users/${props.item.id}`}>{props.item.login}</Link>
                )}/>
            </DataTableColumn>
            <DataTableColumn label="E-Mail" property="email" title={"email"}/>
            <DataTableColumn label="Organisation" property={"organisation"}>
                <CustomDataTableCell key="type" render={(props) => {
                    return <Link to={`/configuration/organisations/${props.item.organisation.id}`}>{props.item.organisation.name}</Link>

                }}/>
            </DataTableColumn>

            <DataTableColumn label={"Created"} property={"createdAt"} title={"createdAt"}>
                <CustomDataTableCell render={(props) => {
                    return <div
                        className="slds-cell-wrap">{moment(props.item.createdAt).fromNow()} ({moment(props.item.createdAt).format('DD.MM.YYYY HH:mm:ss')})</div>
                }}/>
            </DataTableColumn>
        </GenericDataTable>
    </Page>;
};

export default UsersListPage;
