import React from "react";
import {classnames} from "../../wrapper/classnames";
import PropTypes from "prop-types";
import {CustomPropTypes} from "../../propTypes/customPropTypes";

// TODO: Support "ButtonStateful" from slds react package
export default function Button(props) {
    const {
        iconName, noBorder, children, iconPosition, iconSize, iconVariant, variant, disabled,
        onClick, className, type
    } = props;
    let {iconCategory} = props;
    let isIconOnly = !!iconName && !children;
    let isIconAndText = !!iconName && children;
    let hasChildren = !!children;
    return <button disabled={disabled}
                   type={type}
                   className={classnames("slds-button", {
                       "slds-button_icon": isIconOnly,
                       [`slds-button_${variant}`]: hasChildren && !!variant && variant !== "base",
                       "slds-button_icon-border-filled": isIconOnly && !noBorder,
                       [`slds-button_icon-${iconSize}`]: isIconOnly && !!iconSize,
                       [`slds-button_icon-${iconVariant}`]: isIconOnly && !!iconVariant,
                   }, className)}
                   onClick={onClick}
    >
        {iconPosition === "right" ? children : null}
        {iconName && <svg className={classnames("slds-button__icon", {
            [`slds-button__icon_${iconPosition}`]: isIconAndText,
        })} aria-hidden="true">
            <use xlinkHref={`/assets/icons/${iconCategory}-sprite/svg/symbols.svg#${iconName}`}/>
        </svg>}
        {iconPosition !== "right" ? children : null}
    </button>;
}

Button.defaultProps = {
    iconCategory: "utility",
    iconPosition: "left",
    variant: "neutral",
    type: "button",
};

Button.propTypes = {
    children: CustomPropTypes.children,
    className: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(["button", "submit"]),

    disabled: PropTypes.bool,
    noBorder: PropTypes.bool, // icon buttons can have: border, border-filled, border-inverse
    variant: PropTypes.oneOf(['base', 'neutral', 'brand', 'outline-brand', 'destructive', 'success', 'text-destructive', 'icon']),
    // iconName and iconCategory are just for SLDS icons.
    // If other icons are needed add something like "iconUrl" prop
    iconName: PropTypes.string,
    iconCategory: PropTypes.oneOf(['action', 'custom', 'doctype', 'standard', 'utility']),
    iconPosition: PropTypes.oneOf(['left', 'right']),
    iconSize: PropTypes.oneOf(['small', 'x-small', 'xx-small']),
    iconVariant: PropTypes.oneOf(['bare', 'brand', 'error', 'inverse', 'border', 'border-inverse', 'border-filled']), // "more"?
};