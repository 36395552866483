import React from "react";
import Page from "../../common/ui/page";
import {useMutation, useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {useAuthContext} from "../../common/context/authContext";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import {usePagination} from "../../common/hooks/usePagination";
import {useSort} from "../../common/hooks/useSort";
import {useCsvExport} from "../../common/hooks/useCsvExport";
import {useSearch} from "../../common/hooks/useSearch";
import {QUERY_DEVICE_TYPE, QUERY_DEVICE_TYPES} from "../deviceData/deviceDataPage";
import {useRouteMatch} from "react-router-dom";
import {DeviceTypesNav} from "../deviceData/deviceTypesNav";
import {Redirect} from "react-router";
import Url from "../../common/url";
import {MUTATE_UPDATE_DEVICE_TYPE} from "../../configuration/queries";
import {useNotificationContext} from "../../notifications/notificationContext";
import {Log} from "../../common/log";

const QUERY_DEVICES = gql`
    query($orgId: ID!, $devTypeId: ID, $page: PaginationInputType, $sort: SortInputType, $search: String) {
        devices(orgId: $orgId, sort: $sort, page: $page, search: $search, devTypeId: $devTypeId) {
            id
            addr
            name
            description
            configRaw
            propertiesRaw
            lastReceived
            deviceType {
                id
                displayName
            }
            app {
                id
                appId
                name
            }
        }
    }
`;


const DEFAULT_DEVICE_TABLE_CONFIG = {
    cols: [
        {
            heading: "Type",
            csvFormat: "{{deviceType.displayName}}",
            cell: {
                format: "{{deviceType.displayName}}"
            }
        },
        {
            heading: "App",
            csvFormat: "{{app.name}}",
            cell: {
                format: "{{app.name}}"
            }
        },
        {
            heading: "Last Received",
            sortProperty: "lastReceived",
            csvFormat: "{{date lastReceived}}",
            cell: {
                format: "{{date lastReceived}}"
            }
        },
        {
            heading: "Description",
            csvFormat: "{{description}}",
            cell: {
                format: "{{description}}"
            }
        },
    ]
};

const DevicesPage = (props) => {
    const auth = useAuthContext();
    const match = useRouteMatch("/organisation/devices/type/:devTypeId");
    let devTypeId = match?.params?.devTypeId;
    const orgId = auth.organisationId();
    const noSelectMatch = useRouteMatch({
        path: "/organisation/devices/type",
        exact: true,
    });
    if (devTypeId === "all") {
        devTypeId = undefined;
    }

    const page = usePagination();
    const sort = useSort();
    const search = useSearch();
    const notify = useNotificationContext();

    const devicesResult = useQuery(QUERY_DEVICES, {
        variables: {
            orgId: auth.organisationId(),
            page: page.getGraphqlPageInput(),
            sort: sort.getGraphqlSortInput(),
            search: search.getGraphqlSearchInput(),
            devTypeId: devTypeId,
        }
    });

    const deviceTypesResult = useQuery(QUERY_DEVICE_TYPES, {
            variables: {
                orgId: orgId
            }
        }
    );

    const devTypeResult = useQuery(QUERY_DEVICE_TYPE, {
            skip: !devTypeId,
            variables: {
                devTypeId: devTypeId,
            }
        }
    );

    const [updateDeviceType] = useMutation(MUTATE_UPDATE_DEVICE_TYPE, {
        variables: {id: devTypeId},
        refetchQueries: [{
            query: QUERY_DEVICE_TYPE,
            variables: {
                devTypeId: devTypeId,
            }
        }]
    });

    const devType = devTypeResult.data?.deviceType;
    const deviceTypes = deviceTypesResult?.data?.deviceTypes;
    const devices = devicesResult.data?.devices?.map((d) => {
        let properties;
        try {
            properties = JSON.parse(d.propertiesRaw) || {};
        } catch (err) {
            Log.Error("Failed to parse device propertiesRaw", err);
        }
        return {
            ...d,
            properties: properties,
        };
    });
    page.setPageItemCount(devices?.length || 0);

    const csvExport = useCsvExport(QUERY_DEVICES, {
        variables: {
            orgId: auth.organisationId(),
            sort: sort.getGraphqlSortInput()
        },
        dataExtractor: d => d.data.devices
    });

    if (deviceTypes?.length >= 1 && noSelectMatch) {
        return <Redirect to={Url.join(noSelectMatch.url, "all")}/>;
    }

    let tableConfig = devType?.deviceTableConfigRaw && JSON.parse(devType.deviceTableConfigRaw);

    if (!tableConfig) {
        tableConfig = DEFAULT_DEVICE_TABLE_CONFIG;
    }

    const prefixCols = [{
        heading: "Name",
        sortProperty: "name",
        csvFormat: "{{#if name}}{{name}}{{else}}- no name -{{/if}}",
        cell: {
            format: "{{#if name}}{{name}}{{else}}- no name -{{/if}}",
            href: "/#/organisation/devices/{{id}}/device-data",
        }
    },
        {
            heading: "Address",
            sortProperty: "addr",
            csvFormat: "{{addr}}",
            cell: {
                format: "{{addr}}",
                href: "/#/organisation/devices/{{id}}/device-data",
            }
        }];

    let saveTableConfigHandler;
    if (devTypeId) {
        saveTableConfigHandler = (values) => {
            return updateDeviceType({
                variables: {
                    input: {
                        deviceTableConfig: JSON.stringify(values),
                    }
                }
            }).then(() => {
                notify.info("Device Table Config for Device Type updated.");
            })
                .catch((err) => {
                    notify.error("Failed to save Device Table Config", err);
                });
        };
    }

    return <div className="slds-container--fluid">
        <div className="slds-grid">
            <div className="slds-col slds-no-flex">
                <DeviceTypesNav deviceTypes={deviceTypes} baseUrl={"/organisation/devices/type"} showAll={true}/>
            </div>
            <div className="slds-col">
                <Page title={"Devices"} trail={[]}>
                    <GenericDataTable
                        items={devices}
                        id="DevicesTable"
                        selectRows={false}
                        gqlResult={devicesResult}
                        csvExport={csvExport}
                        page={page}
                        sort={sort}
                        search={search}
                        fixedLayout={true}
                        tableConfigDefault={tableConfig}
                        prefixCols={prefixCols}
                        handleTableConfigSave={saveTableConfigHandler}
                    />
                </Page>
            </div>
        </div>
    </div>;
};

export default DevicesPage;