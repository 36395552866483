import React, {useState} from "react";
import {Log} from "../../../common/log";
import {useParams} from "react-router";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/react-hooks";
import DataTable, {Col, EditCellContent, HeaderCol, Row, TableBody, TableHead} from "../../../common/slds/dataTable";
import {Form} from "../../../common/ui/form/formik";
import {FieldArray, Formik, getIn} from "formik";
import {FormActions, SldsSelectField, SubmitButtonField} from "../../../common/ui/form/formElements";
import {useGraphqlLoadingComponent} from "../../../common/graphql";
import Button from "../../../common/slds/buttons/button";
import {useAuthContext} from "../../../common/context/authContext";
import moment from "moment";
import {useNotificationContext} from "../../../notifications/notificationContext";
import ButtonGroup from "../../../common/slds/buttonGroup/ButtonGroup";
import {ConfigPropertyType} from "../../../model/device";

const QUERY_DEVICE = gql`
    query device($devId: ID!) {
        device(id: $devId) {
            id
            appId
            name
            description
            addr
            configuration {
                name
                value
                setValue
                updatedAt
            }
            location {
                lat
                lon
            }
            deviceType {
                id
                configProperties
                deviceTraits
            }
        }
    }
`;

const MUTATION_UPDATE_DEVICE_CONFIG = gql`
    mutation updateConfig($devId: ID!, $input: [DeviceConfigValueInput!]!) {
        updateDeviceConfiguration(devId: $devId, input: $input) {
            id
            configuration {
                name
                value
                setValue
                updatedAt
            }
        }
    }
`;

const MUTATION_SCHEDULE_CONFIG_DOWNLINK = gql`
    mutation scheduleDownlink($devId: ID!) {
        scheduleDeviceConfigUpdate(devId: $devId) {
            id
        }

    }
`;

const getDeviceConfigProps = (device, propertyInfo) => {
    if (!device) {
        return [];
    }

    let config = propertyInfo.map(pi => {
        return {
            name: pi.name,
            type: pi.type,
            setValue: null, // Should not be undefined!
        };
    });

    try {
        device.configuration.forEach((val) => {
            let c = config.find(c => c.name === val.name);
            if (c !== undefined) {
                c.value = val.value;
                c.setValue = val.setValue;
                c.updatedAt = val.updatedAt;
            } else {
                // TODO: Note that we do not have schema for this value!
                // Needs special handling in UI?
                config.push({
                    name: val.name,
                    value: val.value,
                    type: val.type,
                    setValue: val.setValue,
                    updatedAt: val.updatedAt,
                });
            }
        });

        // avoid undefined "value" in form, else component is uncontrolled
        /*
        configuration = configuration.map(p => {
            if (p.setValue === undefined) {
                p.setValue = "";
            }
            return p;
        });*/

    } catch (e) {
        Log.Error("Failed to parse device propertiesRaw: ", e);
    }
    return config;
};

const getConfigPropertyInfo = (propertyInfo, prop) => {
    return propertyInfo.find((pi => pi.name === prop.name));
};

export default function DeviceConfig(props) {
    const params = useParams();
    const devId = params.deviceId;
    const auth = useAuthContext();
    const notify = useNotificationContext();
    const [editCtx, setEditCtx] = useState({});

    const gqlResult = useQuery(QUERY_DEVICE, {
        fetchPolicy: "cache-and-network",
        variables: {devId: devId},
    });

    const [updateDeviceConfig] = useMutation(MUTATION_UPDATE_DEVICE_CONFIG, {
        variables: {
            devId: devId,
        }
    });
    const [scheduleConfigDownlink] = useMutation(MUTATION_SCHEDULE_CONFIG_DOWNLINK, {
        variables: {
            devId: devId,
        }
    });

    const loadingError = useGraphqlLoadingComponent(gqlResult);
    if (loadingError) {
        return loadingError;
    }

    const {device} = gqlResult?.data || {};
    const {deviceType} = device;
    const hasRemoteConfig = deviceType.deviceTraits.find(t => t === "remote-config");

    const canEdit = hasRemoteConfig && (auth.hasRole("admin") || auth.hasRole("org-admin"));
    const propertyInfo = JSON.parse(device?.deviceType?.configProperties || "[]") || [];

    const configProps = getDeviceConfigProps(device, propertyInfo);

    return <div>
        <Formik
            enableReinitialize={true}
            initialValues={{
                configProps: configProps,
            }}
            onSubmit={(values, actions) => {
                Log.Debug("submit values:", values);
                const props = values.configProps.map((p) => {
                    return {
                        name: p.name,
                        setValue: (p.setValue === null) ? null : `${p.setValue}`,
                    };
                });
                Log.Debug("submit props:", props);
                updateDeviceConfig({
                    variables: {
                        input: props,
                    }
                }).then(() => {
                    scheduleConfigDownlink().then(() => {
                        notify.info("Updated device props & scheduled downlink");
                    }).catch((err) => {
                        notify.error("Failed to schedule config downlink", err);
                    });

                }).catch((err) => {
                    notify.error("Failed to update device props", err);
                });
            }}
        >
            <Form>
                <DataTable fixedLayout={false}>
                    <TableHead>
                        <HeaderCol width="50px">Name</HeaderCol>
                        <HeaderCol width="50px">Current Value</HeaderCol>
                        <HeaderCol>Target Value</HeaderCol>
                        <HeaderCol>&nbsp;</HeaderCol>
                        <HeaderCol>Updated at</HeaderCol>
                    </TableHead>
                    <TableBody>
                        <FieldArray name={"configProps"}>{((arrayHelper) => {
                            const form = arrayHelper.form;
                            const {values} = form;
                            //Log.Debug("array formik", arrayHelper.form);

                            const DefaultCrons = {
                                "": {value: "", label: "None"},
                                EVERY_MIN: {value: "0 0/1 * * * *", label: "Every minute"},
                                EVERY_10_MIN: {value: "0 0/10 * * * *", label: "Every 10 minutes"},
                                EVERY_15_MIN: {value: "0 0/15 * * * *", label: "Every 15 minutes"},
                                EVERY_HOUR: {value: "0 0 0/1 * * *", label: "Every hour"},
                                EVERY_3_HOUR: {value: "0 0 0/3 * * *", label: "Every 3 hours"},
                                EVERY_8_HOUR: {value: "0 0 0/8 * * *", label: "Every 8 hours"},
                                EVERY_12_HOUR: {value: "0 0 0/12 * * *", label: "Every 12 hours"},
                                EVERY_DAY: {value: "0 0 10 * * *", label: "Daily at 10am"},
                            };
                            DefaultCrons.asList = function () {
                                return _.keys(DefaultCrons).map(k => {
                                    return DefaultCrons[k];
                                });
                            };

                            return values.configProps.map((p, i) => {
                                const fieldName = `configProps.${i}.setValue`;
                                const field = arrayHelper.form.getFieldProps(fieldName);
                                // TODO: See TODO above, about config values without a schema! Might be undefined then
                                const info = getConfigPropertyInfo(propertyInfo, p);
                                return <Row key={`${p.name}-${i}`}>
                                    <Col>{p.name}{info ? "" : " *"}</Col>
                                    <Col wrap={true}  width={"30%"}>{p.value}</Col>
                                    <Col wrap={true} width={"30%"}>
                                        <EditCellContent
                                            truncate={false} readOnly={!canEdit}
                                            onEdit={() => {
                                                if (editCtx.isEditing) {
                                                    return false; // Do not allow to edit
                                                }

                                                let initialValue = getIn(form.values, fieldName);
                                                setEditCtx({initialValue: initialValue, isEditing: true});
                                            }}
                                            onStopEdit={() => {
                                                setEditCtx({initialValue: null, isEditing: false});
                                            }}
                                            renderInput={({inputRef, stopEdit}) => {
                                                return <><input ref={inputRef} type="text"
                                                                className="slds-input"
                                                                {...field}
                                                                onFocus={() => {
                                                                    // Empty value should be empty string to allow setting values blank
                                                                    // null would be "do not update"
                                                                    if (p.setValue === null) {
                                                                        form.setFieldValue(fieldName, "", false);
                                                                    }
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === "Enter") {
                                                                        e.preventDefault();
                                                                        stopEdit();
                                                                    }
                                                                    if (e.key === "Escape") {
                                                                        e.preventDefault();
                                                                        stopEdit();
                                                                    }
                                                                }}
                                                />
                                                    {p.type === ConfigPropertyType.CRON.value ?
                                                        <SldsSelectField label={"Use predefined CRON"} name={fieldName} options={DefaultCrons.asList()}/>
                                                        : null}
                                                    <div className="slds-p-top--small">
                                                        <ButtonGroup hasSpace={true}>
                                                            <Button iconName={"save"} variant={"brand"} onClick={() => stopEdit()}>Set</Button>
                                                            <Button variant={"destructive"} onClick={() => {
                                                                form.setFieldValue(fieldName, null, false);
                                                                stopEdit();
                                                            }}>Do not update</Button>
                                                            <Button variant={"destructive"} onClick={() => {
                                                                form.setFieldValue(fieldName, editCtx.initialValue, false);
                                                                stopEdit();
                                                            }}>Cancel</Button>
                                                        </ButtonGroup>
                                                    </div>
                                                </>;
                                            }}>
                                            {p.setValue === null ? <i>- do not update -</i> : p.setValue}
                                        </EditCellContent></Col>
                                    <Col>
                                        {canEdit ?
                                            <Button iconName={"clear"} iconSize={"small"} noBorder={true} onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                form.setFieldValue(fieldName, null, false);

                                            }}/> : null}
                                    </Col>
                                    <Col>{p.updatedAt ? moment(p.updatedAt).format('DD.MM.YYYY HH:mm:ss') : "never"}</Col>
                                </Row>;
                            });
                        })}</FieldArray>


                    </TableBody>
                </DataTable>
                <p>
                    * Missing in device type!
                </p>
                {canEdit ?
                    <FormActions>
                        <SubmitButtonField>Update config</SubmitButtonField>
                    </FormActions> : null}
            </Form>
        </Formik>

    </div>;
}