import React from "react";
import * as PropTypes from "prop-types";
import * as log from "../../../common/log";
import {Formik} from "formik";
import {CancelButtonField, FormActions, SldsFormElement, SldsInputField, SubmitButtonField} from "../../../common/ui/form/formElements";
import {Button, Icon} from "@salesforce/design-system-react";
import {Form} from "../../../common/ui/form/formik";
import CodeEditor from "../../../common/ui/codeEditor";

const defaultTestInput = `{
  "battery": 3.3
}`;

export default class EditAlarmConfigForm extends React.Component {

    onTestCondition(formik) {
        this.props.onTestCondition(formik.values)
            .then((result) => {
                formik.setFieldValue('testResult', result.data.alarm);
            })
    }

    renderForm(formik) {
        //const {readOnly} = this.state;
        const {canEdit} = this.props;
        const {readOnly} = formik.status;
        const testResult = formik.values['testResult'];
        return <Form stacked={true}>
            <SldsInputField label="Alarm Name" name="name" placeholder="Name of the alarm" id="name"/>
            <SldsInputField label="No-Alarm Name" name="nameNoAlarm" placeholder="Name of the alarm when there is no alarm" id="nameNoAlarm"/>
            <SldsInputField label="Email Notification" name="email" placeholder="mail@example.com, other@example.com"/>

            <SldsInputField label="Condition" name="triggerJs" placeholder="Condition (e.g. data.sensors[1].temperature > 60.0)" id="triggerJs"/>


            {readOnly ? null :
                <SldsFormElement>
                    <div className="slds-grid slds-m-top--small">
                        <div className="slds-col slds-m-right--medium xslds-size_1-of-6">
                            <Button disabled={formik.isSubmitting} onClick={() => this.onTestCondition(formik)}>Test Condition</Button>
                            <div className="slds-text-heading--small  slds-m-top--x-small">Result: <Icon name={testResult.active ? "alert" : "check"} category={"utility"}/></div>

                            <div>{testResult.reason}</div>
                        </div>
                        <div className="slds-col xslds-size_4-of-6">
                            <div className="slds-text-heading--medium">Test Input (Device Data)</div>
                            <CodeEditor id={"input"} defaultValue={defaultTestInput} minLines={3} maxLines={10}
                                        value={formik.values["testInput"]}
                                        onChange={(val, ev) => {
                                            formik.setFieldValue("testInput", val);
                                            this.setState({testInput: val})
                                        }}
                            />
                        </div>
                    </div>
                </SldsFormElement>
            }

            <FormActions>
                <SubmitButtonField>Save</SubmitButtonField>
                <CancelButtonField>Cancel</CancelButtonField>
            </FormActions>
        </Form>
    }

    render() {
        const {onUpdate, alarmConfig, canEdit} = this.props;
        log.Debug("EditAlarmConfigForm:", alarmConfig, canEdit);

        return <div>
            <Formik
                initialValues={{...alarmConfig, testResult: "-"}}
                enableReinitialize={true}
                initialStatus={{readOnly: true, canEdit: canEdit}}
                validate={(values, props) => {
                    let errors = {};
                    if (!values.name) {
                        errors.name = "Name must not be empty"
                    }
                    return errors
                }}
                onSubmit={(values, actions) => {
                    log.Debug("Submit: ", values, actions);
                    Promise.resolve(onUpdate(values, actions)).then(() => {
                        actions.setSubmitting(false);
                    }, (err) => {
                        alert("Failed to save alarm configuration");
                        log.Error("Failed to save AlarmConfig", err);
                        actions.setSubmitting(false);
                    });
                }}
                render={(formik) => this.renderForm(formik)}
            />

        </div>
    }
}

EditAlarmConfigForm.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    onTestCondition: PropTypes.func.isRequired,
    alarmConfig: PropTypes.object.isRequired,
    canEdit: PropTypes.bool,
};