import * as React from "react";
import {useContext} from "react";
import {Button} from "@salesforce/design-system-react";
import * as PropTypes from "prop-types";
import gql from "graphql-tag";
import * as log from "../../../common/log";
import EditDeviceForm from "./editDeviceForm";
import ParserForm from "../../../common/ui/parserForm";
import {DangerZone} from "../../../common/ui/dangerZone";
import {useAuthContext} from "../../../common/context/authContext";
import {RouterContext} from "../../../common/RouterContext";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {useGraphqlLoadingComponent} from "../../../common/graphql";
import {NotifyUser} from "../../../common/userNotification";
import {CustomPropTypes} from "../../../common/propTypes/customPropTypes";
import {Formik} from "formik";
import {FormActions, SubmitButtonField} from "../../../common/ui/form/formElements";
import {Form} from "../../../common/ui/form/formik";
import CodeEditor from "../../../common/ui/codeEditor";
import {backendUrl} from "../../../common/helper";
import authenticatedFetch from "../../../common/authenticatedFetch";
import {QUERY_DEVICE} from "./deviceDetailPage";

const MUTATION_DELETE_DEVICE = gql`
    mutation deleteDevice($devId: ID!) {
        deleteDeviceById(id:$devId) {
            id
            name
        }
    }
`;

const MUTATION_UPDATE_DEVICE = gql`
    mutation updateDevice($devId: ID!, $device: DeviceInput!) {
        updateDevice(id: $devId, input: $device) {
            id
            appId
            name
            addr
            description
            addr
            parserCode
            parserEnabled
            deviceType {
                id
                name
                displayName
                parserCode
                parserEnabled
            }
        }
    }
`;

export default function DeviceSettings(props) {
    const auth = useAuthContext();
    const {match, history} = useContext(RouterContext);
    const devId = match.params.deviceId;

    const deviceResult = useQuery(QUERY_DEVICE, {
        fetchPolicy: 'cache-first',
        variables: {devId: devId},
    });

    const [mutationUpdateDevice] = useMutation(MUTATION_UPDATE_DEVICE, {
        variables: {
            devId: devId
        }
    });
    const [mutationDeleteDevice] = useMutation(MUTATION_DELETE_DEVICE, {
        variables: {devId: devId},
    });

    const loading = useGraphqlLoadingComponent(deviceResult);
    if (loading) {
        return loading;
    }


    const {device, deviceTypes} = deviceResult.data;

    function updateDevice(device) {
        const mut = mutationUpdateDevice({
            variables: {
                device: {
                    name: device.name,
                    description: device.description,
                    deviceTypeId: device.deviceType.id,
                    addr: device.addr,
                    appId: device.app?.id || 0, // 0 to clear the app ID, null would be ignored!
                    datasource: {
                        type: device.datasource.type,
                    },
                }
            }
        });

        log.Debug("Update device:", device);
        return mut;
    }

    function deleteDevice(props) {
        if (window.confirm(`Delete device "${device.name || "Device"}" [${devId}] with all it's data?`)) {

            mutationDeleteDevice().then(() => {
                history.push("..");
            }, (err) => {
                NotifyUser.Error("Failed to delete device:", err);
            });
        }
    }

    function saveParser(values, actions) {
        const {parserCode, parserSelection} = values;
        const parserEnabled = (parserSelection !== "default");


        mutationUpdateDevice({
            mutation: MUTATION_UPDATE_DEVICE,
            variables: {
                device: {
                    parserCode: parserCode,
                    parserEnabled: parserEnabled,
                }
            }
        }).then(() => {
            log.Debug("Updated parser:", device);
        }, (err) => {
            NotifyUser.Error("Failed to save parser.", err);
        }).finally(() => {
            actions.setSubmitting(false);
        });
    }

    const inputCode = (device.sensorData[0] || {}).dataRaw || `{"info": "No data received yet"}`;
    let defaultParserCode = device.deviceType.parserEnabled ? device.deviceType.parserCode : "// Using hardcoded GoLang parser";


    return <div className="slds-m-left--x-small">
        <EditDeviceForm device={device}
                        deviceTypes={deviceTypes}
                        onUpdate={(values, actions) => updateDevice(values)}
        />

        {auth.hasRole("admin") &&
        <ParserForm defaultParserCode={defaultParserCode}
                    parserCode={device.parserCode}
                    inputCode={inputCode}
                    parserSelection={device.parserEnabled ? "custom" : "default"}
                    saveParser={(values, actions) => saveParser(values, actions)}
        />}

        {auth.hasRole("admin") && <>
            <div className="slds-text-heading--medium slds-m-vertical--small">Simulate Uplink</div>
            <Formik
                initialValues={{payload: `{}`}}
                onSubmit={(values, actions) => {
                    authenticatedFetch(`${backendUrl()}/api/devices/${device.id}/uplink`, {
                        method: "POST",
                        body: values.payload,
                    }).then(() => {
                        NotifyUser.Info("Payload was sent to device");
                    }).catch(err => {
                        NotifyUser.Error("Failed to send payload", err);
                    });
                }}
                render={(formik) => {
                    return <Form>
                        <div className="slds-size_3-of-6">
                            <CodeEditor id={"editor-parser"}
                                        defaultValue={formik.values["payload"]}
                                        minLines={15}
                                        maxLines={30}
                                        value={formik.values["payload"]}
                                        onChange={(val, ev) => {
                                            formik.setFieldValue("payload", val);
                                        }}/>
                            <FormActions>
                                <SubmitButtonField iconName={"send"}>Send data</SubmitButtonField>
                            </FormActions>
                        </div>
                    </Form>;
                }}
            >
            </Formik>
        </>}


        <DangerZone>
            <Button type={"button"} variant={"destructive"} onClick={() => deleteDevice(props)}>Delete Device</Button>
        </DangerZone>
    </div>;
}

DeviceSettings.propTypes = {
    // From Router
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    // From GraphQL result
    client: CustomPropTypes.deprecated("Not used anymore"),
    data: CustomPropTypes.deprecated("Not used anymore"),
};

