import {useState} from "react";

// Helper to get some pagination state
export function usePagination(pageSize = 100) {
    const [pageInfo, setPageInfo] = useState({offset: 0, limit: pageSize, total: 0});
    let pageItemCount = pageInfo.limit; // Used to guess if there is a next page when total  == 0

    function getGraphqlPageInput() {
        if (!pageInfo.offset && !pageInfo.limit) {
            return null;
        }
        return {
            offset: pageInfo.offset || 0,
            limit: pageInfo.limit || 100,
        }
    }

    function nextOffset() {
        if (pageInfo.total === 0) {
            return pageInfo.offset + pageInfo.limit;
        }

        if (pageInfo.offset + pageInfo.limit > pageInfo.total - pageInfo.limit) {
            return pageInfo.total - pageInfo.limit;
        }
        return pageInfo.offset + pageInfo.limit;
    }

    function prevOffset() {
        if (pageInfo.offset - pageInfo.limit < 0) {
            return 0
        }
        return pageInfo.offset - pageInfo.limit
    }


    return {
        pageInfo: pageInfo,
        setPageInfo: setPageInfo,
        currentPage: () => {
            return Math.ceil(pageInfo.offset / pageInfo.limit) + 1
        },
        totalPages: () => {
            return Math.ceil(pageInfo.total / pageInfo.limit)
        },
        hasPrev: () => pageInfo.offset > 0,
        hasNext: () => {
            return pageInfo.offset + pageInfo.limit < pageInfo.total || (pageInfo.total === 0 && pageItemCount === pageInfo.limit)
        },
        next: () => {
            setPageInfo({
                offset: nextOffset(),
                limit: pageInfo.limit,
                total: pageInfo.total,
            });
        },
        prev: () => {
            setPageInfo({
                offset: prevOffset(),
                limit: pageInfo.limit,
                total: pageInfo.total,
            });
        },
        getGraphqlPageInput,
        // You can update the actual page item count when the "total" value is not known to better guess if there is a next page
        setPageItemCount: (cnt) => {
            pageItemCount = cnt;
        },
        getPageItemCount: () => pageItemCount,
    }
}