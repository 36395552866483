import React from "react";
import PropTypes from "prop-types";
import {Button} from "@salesforce/design-system-react";
import classNames from 'classnames';
import {Field, Formik} from 'formik';
import {DateTimePicker, SldsFormElementCompound, SldsFormElementRow} from './form/formElements'
import {Form} from './form/formik'
import * as log from "../log";

// Map the filters to graphQL "FilterInputType"
// [{"field": "a", "op": "lte", "value": "123"}]
function filtersToGraphQL(filters) {
    let gqlFilters = [];

    if (!filters) {
        return gqlFilters;
    }

    if (filters['until']) {
        gqlFilters.push({"field": 'createdAt', "op": "lte", "value": filters['until']})
    }
    if (filters['from']) {
        gqlFilters.push({"field": 'createdAt', "op": "gte", "value": filters['from']})
    }
    return gqlFilters;
}


export default class FilterPanel extends React.Component {

    static propTypes = {
        align: PropTypes.oneOf(["center", "left", "right"]),
        size: PropTypes.oneOf(["small", "medium", "large", "x-large", "full"]),
        onClose: PropTypes.func,
        onApply: PropTypes.func.isRequired,
        filters: PropTypes.object // From useFilter() Hook
    };

    static defaultProps = {
        align: "left",
        size: "medium",
        filters: {
            'until': new Date(),
        }
    };

    state = {
        filters: {} // Key value map of filters
    };

    constructor(props) {
        super(props);

        this.state.filters = this.props.filters;
    }

    render() {
        const {align, className, size, onClose} = this.props;
        const {filters} = this.state;
        const docked = align !== 'center';

        log.Debug("Filters:", filters)

        return <div
            className={
                classNames(
                    {
                        'slds-is-open': true,
                        [`slds-panel_docked-${align}`]: docked,
                        'slds-panel_docked"': docked,
                    },
                    `slds-size_${size}`,
                    "lds-panel",
                    className,
                )}

            aria-hidden="false">
            <div className="slds-panel__header">
                <div className="slds-panel__header-title slds-text-heading_small slds-truncate"
                    title="Panel Header">Filter</div>
                <Button iconCategory="utility"
                        variant="icon"
                        iconName="close"
                        iconSize="small"
                        assistiveText={{icon: "Close filter panel"}}
                        className="slds-button--icon slds-panel__close"
                        onClick={onClose}
                />
            </div>
            <div className="slds-panel__body">
                {/* Render the filter options*/}
                <Formik
                    initialValues={filters}
                    onSubmit={(values, actions) => {
                        log.Debug("Submit: ", values, actions);
                        this.setState({filters: values});
                        const filters = filtersToGraphQL(values);
                        Promise.resolve(this.props.onApply(filters, actions))
                            .finally(() => {
                                actions.setSubmitting(false);
                            });
                    }}
                    render={(formik) => {
                        return <Form>
                            <SldsFormElementCompound>
                                <SldsFormElementRow>
                                    <Field component={DateTimePicker} label="Show data before" name="until"/>
                                    {/*<Field component={DateTimePicker} label="Show data after" name="from"/>*/}
                                </SldsFormElementRow>
                            </SldsFormElementCompound>
                            <Button type="submit" disabled={formik.isSubmitting}>Apply Filter</Button>
                        </Form>
                    }
                    }
                />
            </div>
        </div>

    }
}