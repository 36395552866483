import React from "react"
import PropTypes from "prop-types"


const Pill = (props) => {
    const {children, className, label, title, onRemove} = props;
    return <span className="slds-pill" role="option" tabIndex="0" aria-selected="true">
        <span className="slds-pill__label" title={title}>{label}</span>
        {onRemove && <span className="slds-icon_container slds-pill__remove" title="Remove" onClick={onRemove}>
          <svg className="slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#close"/>
          </svg>
          <span className="slds-assistive-text">Press delete or backspace to remove</span>
        </span>}
      </span>
};

Pill.propTypes = {
    //children: PropTypes.any.isRequired,
    //className: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string,
    onRemove: PropTypes.func, // If set, pill is removable

};

export default Pill