import React from "react";
import PropTypes from "prop-types";
import {backendUrl} from "../common/helper";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import {Log} from "../common/log";
import {useAuthContext} from "../common/context/authContext";

const QUERY_ORGANISATION = gql`
    query ($orgId: ID!) {
        organisation: getOrganisation(id: $orgId) {
            id
            name
            logoUrl
        }
    }
`;

export default function HeaderOrganisationLogo(props) {
    const {logoUrl} = props;

    const authCtx = useAuthContext();
    const orgResult = useQuery(QUERY_ORGANISATION, {
        fetchPolicy: "cache-first",
        variables: {
            orgId: authCtx.organisationId(),
        }
    });

    const org = orgResult.data?.organisation;

    let logoCssUrl = `url(/static/img/logos/lobaro.svg)`;
    if (org?.logoUrl) {
        logoCssUrl = `url(${backendUrl()}/${org?.logoUrl})`;
    }

    return <div className="slds-global-header__logo"
                style={{backgroundImage: logoCssUrl}}/>;
}

HeaderOrganisationLogo.propTypes = {
    logoUrl: PropTypes.string,
};