import React, {useContext} from "react";
import {AppContext} from "../app/appPage";
import {Log} from "../common/log";
import {useQuery} from "@apollo/react-hooks";
import {QUERY_APPLICATION} from "../app/queries";
import {backendUrl} from "../common/helper";
import LobaroLogo from "../lobaroLogo";

const HeaderAppLogo = (props) => {
    const app = useContext(AppContext);
    Log.Debug("Logo AppContext ", app);

    const appResult = useQuery(QUERY_APPLICATION, {
        variables: {appId: app.id}
    });

    if (appResult.loading) {
        return <></>;
    }

    const appInstance = appResult.data && appResult.data.appInstance;
    Log.Debug("Logo result ", appResult);
    if (!appInstance.logo || appInstance.logo === "default") {
        return <LobaroLogo/>;
    }
    const logoUrl = backendUrl() + "/data/" + appInstance.logo;

    return <div className="slds-global-header__logo" style={{backgroundImage: `url(${logoUrl})`}}/>;
};

export default HeaderAppLogo;