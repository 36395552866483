import Lookup from "../../common/ui/lookup/lookup";
import {Icon} from "../../common/slds/icons/icon";
import {insert, useFormikContext} from "formik";
import * as Log from "../../common/log";
import {PillContainerField} from "../../common/ui/form/pillContainerField";
import React from "react";
import PropTypes from "prop-types";

const ROLES = [
    {
        name: "admin",
        displayName: "Admin",
    },
    {
        name: "org-admin",
        displayName: "Organisation Admin"
    },
];

function getRole(name) {
    return ROLES.find(it => it.name === name) || {name: name, displayName: name + "*"};
}

export default function UserRolesField(props) {
    const formik = useFormikContext();
    return <PillContainerField name={"roles"} label={"Roles"} pillLabelExtractor={(it) => getRole(it).displayName}
                               renderLookup={() => {
                                   return <Lookup
                                       name={"roles"}
                                       placeholder={"Add Role ..."}
                                       iconExtractor={() => <Icon category="standard" name="user_role" size="small"/>}
                                       titleExtractor={(r) => r.name}
                                       subtitleExtractor={(r) => undefined}
                                       valueExtractor={(option) => option.name}
                                       onLookup={(value) => {
                                           let roles = formik.values["roles"] || [];
                                           formik.setFieldValue("roles", insert(roles, roles.length, value));
                                       }}
                                       loadSuggestions={(value, formik) => {
                                           Log.Debug("Suggestions", value, formik);
                                           const search = value.toLowerCase();
                                           return ROLES.map((t) => {
                                               const displayName = getRole(t.type).displayName.toLowerCase();
                                               const name = t.name.toLowerCase();

                                               if (name.includes(search) || displayName.includes(search)) {
                                                   return t;
                                               }
                                           })
                                               .filter(it => !!it)
                                               .filter(item => !formik?.form?.values?.roles || formik?.form?.values?.roles?.findIndex(it => item.name === it.name) === -1);
                                       }}
                                   />;
                               }}
    />;
}

UserRolesField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
};