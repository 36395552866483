import React from "react"
import {Formik} from "formik";
import {Modal} from "../common/slds";
import {Log} from "../common/log";
import gql from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {CancelButtonField, FormActions, SldsInputField, SubmitButtonField} from "../common/ui/form/formElements";
import * as yup from "yup";
import {Form} from "../common/ui/form/formik";
import {useAuthContext} from "../common/context/authContext";
import PropTypes from "prop-types"

const ChangePasswordDialog = (props) => {
    let {isOpen, onRequestClose, userId} = props;

    let [mutateChangePassword] = useMutation(gql`
        mutation($id: ID!, $input: UserInput) {
            updateUser(id: $id, input: $input) {
                id
            }
        }
    `, {
        variables: {}
    });

    return <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <Formik
            initialValues={{}}
            validationSchema={yup.object().shape({
                password: yup
                    .string()
                    .label('New Password')
                    .required()
                    .min(8, 'Must be at least 8 characters')
                    .max(20, 'Can have max. 20 characters'),
                confirmPassword: yup
                    .string()
                    .required()
                    .label('Confirm New Password')
                    .test('passwords-match', 'Passwords do not match', function (value) {
                        return this.parent.password === value;
                    })
            })
            }
            onSubmit={(values, actions) => {
                mutateChangePassword({
                    variables: {
                        id: userId,
                        input: {
                            password: values.password
                        }
                    }
                }).then(() => {
                    onRequestClose();
                }, (e) => {
                    actions.setFieldError("global", e);
                    Log.Error("Failed to create hardware", e);
                }).finally(() => {
                    actions.setSubmitting(false);
                })
            }}
        >
            <Form>
                <SldsInputField name="password" label={"New Password"} type="password"/>
                <SldsInputField name="confirmPassword" label={"Confirm New Password"} type="password"/>
                <FormActions>
                    <SubmitButtonField>Change Password</SubmitButtonField>
                    <CancelButtonField onClick={onRequestClose}>Cancel</CancelButtonField>
                </FormActions>
            </Form>
        </Formik>
    </Modal>;
};
ChangePasswordDialog.propTypes = {
    userId: PropTypes.string.isRequired, // String comes from GraphQL ID
};

export default ChangePasswordDialog;