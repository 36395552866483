import {useState} from "react";

class Sort {
    sortInfo = {}
    setSortInfo = () => {}

    constructor(sortInfo, setSortInfo) {
        this.sortInfo = sortInfo;
        this.setSortInfo = setSortInfo;
    }

    getGraphqlSortInput() {
        if (!this.sortInfo.property) {
            return null;
        }
        return {
            field: this.sortInfo.property || "",
            direction: this.sortInfo.sortDirection || "ASC",
        }
    }

    isSorted() {
        return !!this.sortInfo.property;
    }
}

export function useSort() {
    const [sortInfo, setSortInfo] = useState({});
    return new Sort(sortInfo, setSortInfo);
}