import * as React from "react";
import {useContext} from "react";
import {Button, Card, Icon} from "@salesforce/design-system-react";
import {Form, Formik, insert} from "formik";
import {FormActions, SldsFormElementCompound, SldsFormElementRow, SldsInput, SldsInputField, SldsSelectField, SldsTextarea} from "../../common/ui/form/formElements";
import gql from "graphql-tag";
import * as log from "../../common/log";
import {Log} from "../../common/log";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {AppContext} from "../../app/appPage";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {decodeToken, useAuthContext} from "../../common/context/authContext";
import {PillContainerField} from "../../common/ui/form/pillContainerField";
import Lookup from "../../common/ui/lookup/lookup";

const ROLES = [
    {
        name: "network-server",
        displayName: "Network Server",
    },
];

function getRole(name) {
    return ROLES.find(it => it.name === name) || {name: name, displayName: name + "*"};
}

const MUTATION_CREATE_API_TOKEN = gql`
    mutation createToken($apiToken: ApiTokenInput!) {
        createApiToken(input: $apiToken) {
            id
            name
            token
            organisationId
        }
    }
`;


const MUTATION_DELETE_API_TOKEN = gql`
    mutation createToken($tokenId: ID!) {
        deleteApiToken(id: $tokenId) {
            id
            name
            token
        }
    }
`;

const QUERY_API_TOKENS = gql`
    query apiTokens($appId: ID, $orgId: ID) {
        apiTokenList(appId: $appId, organisationId: $orgId) {
            id
            appId
            organisationId
            name
            token
            password
        }
    }
`;

const refetchApiTokens = (orgId, appId) => ({
    query: QUERY_API_TOKENS,
    variables: {
        appId: appId,
        orgId: orgId,
    }
});


export default function RestApiTab(props) {
    const app = useContext(AppContext);
    const auth = useAuthContext();
    const orgId = auth.organisationId();

    const apiTokensResult = useQuery(QUERY_API_TOKENS, {
        variables: {
            orgId: orgId,
            appId: app.id,
        }
    });

    const [createTokenMutation] = useMutation(MUTATION_CREATE_API_TOKEN, {
        refetchQueries: [refetchApiTokens(orgId, app.id)],
    });
    const [deleteTokenMutation] = useMutation(MUTATION_DELETE_API_TOKEN, {
        refetchQueries: [refetchApiTokens(orgId, app.id)],
    });

    const loading = useGraphqlLoadingComponent(apiTokensResult);
    if (loading) {
        return loading;
    }

    const {data} = apiTokensResult;
    let apiTokens = data.apiTokenList;
    apiTokens = apiTokens.map((t) => {
        return {
            ...t,
            tokenParsed: decodeToken(t.token),
        };
    });


    function deleteToken(t) {

        if (!window.confirm(`Delete API Token '${t.name}' [${t.id}]?`)) {
            return;
        }

        deleteTokenMutation({
            variables: {
                tokenId: t.id
            }
        }).catch((err) => {
            log.Error("Failed to delete api token:", err);
            alert("Failed to delete api token.");
        });
    }


    return <div className="slds-m-horizontal--x-small">
        <div className="slds-text-heading--medium slds-m-bottom--small">API Documentation</div>
        <p>REST API provided by the backend to fetch data.</p>
        <ul className="slds-list--dotted">
            <li><a href="https://docs.lobaro.com/dashboard" target="_blank">docs.lobaro.com</a></li>
            <li><a href="https://backend.lobaro.com/api" target="_blank">Swagger Documentation & API Client</a></li>
        </ul>
        <hr/>

        <div className="slds-text-heading--medium">API Credentials</div>
        {
            apiTokens.map((t) => {
                return <Card key={t.id}
                             heading={t.name}
                             className="slds-card_boundary slds-p-horizontal--x-small"
                             icon={<Icon category="standard" name="data_integration_hub" size="small"/>}
                             headerActions={<div><Button variant="destructive" onClick={() => deleteToken(t)}>Delete</Button></div>}
                >
                    { // When a password is set, show only BasicAuth credentials, else show the token
                        t.password ?
                            <div className="slds-m-bottom--x-small">
                                <SldsFormElementCompound label={"BasicAuth"}>
                                    <SldsFormElementRow>
                                        <SldsInput label="Username" field={{value: "token-" + t.id}}/>
                                        <SldsInput label="Password" field={{value: t.password}}/>
                                        <SldsInput label="BasicAuth Header" field={{value: "Basic " + btoa("token-" + t.id + ":" + t.password)}}/>
                                    </SldsFormElementRow>
                                </SldsFormElementCompound>
                            </div>
                            :
                            <SldsTextarea label={"Authorization Header"} rows={3} field={{value: "Bearer " + t.token}}/>
                    }

                    <Formik
                        onSubmit={() => {
                        }}
                        initialValues={{...t, "roles": t.tokenParsed.roles}}
                        render={(formik) => {
                            return <>
                                <PillContainerField pillLabelExtractor={(r) => r} name={"roles"} label={"Roles"} readOnly={true}/>
                            </>;
                        }}
                    />


                    {/*<Json json={t.tokenParsed}/>*/}
                </Card>;
            })
        }
        <Card
              heading={"Create New Credentials"}
              className="slds-card_boundary slds-p-horizontal--x-small"
              icon={<Icon category="utility" name="record_create" size="small"/>}
        >

            <Formik initialValues={{
                name: "API Credentials",
                roles: [],
            }}
                    validate={(values, props) => {
                        let errors = {};
                        if (!values.name) {
                            errors.name = "Name must not be empty";
                        }
                        return errors;
                    }}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                        createTokenMutation({
                            variables: {
                                apiToken: {
                                    appId: app.id,
                                    organisationId: orgId,
                                    name: values.name,
                                    roles: values.roles || [],
                                    generatePassword: values.type === "basic-auth",
                                }
                            }
                        })
                            .catch((err) => {
                                Log.Error("Failed to create token", err);
                                alert("Failed to create token");
                            })
                            .finally(() => {
                                actions.setSubmitting(false);
                            });
                    }}
            >{(formik) => {
                return <Form className="slds-m-bottom--medium">
                    <SldsInputField className="slds-m-top--small" name={"name"} label={"Name"}/>
                    <SldsSelectField label={"Type"} name={"type"} options={[
                        {label: "Bearer Token", value: "bearer"},
                        {label: "BasicAuth", value: "basic-auth"},
                    ]}/>
                    <PillContainerField name={"roles"} label={"Roles"} pillLabelExtractor={(it) => getRole(it).displayName}
                                        renderLookup={() => {
                                            return <Lookup
                                                placeholder={"Add Role ..."}
                                                iconExtractor={() => <Icon category="standard" name="user_role" size="small"/>}
                                                titleExtractor={(r) => r.name}
                                                subtitleExtractor={(r) => undefined}
                                                valueExtractor={(option) => option.name}
                                                onLookup={(value) => {
                                                    let list = formik.values["roles"] || [];
                                                    formik.setFieldValue("roles", insert(list, list.length, value));
                                                }}
                                                loadSuggestions={(value, formik) => {
                                                    Log.Debug("Suggestions", value, formik);
                                                    const search = value.toLowerCase();
                                                    return ROLES.map((t) => {
                                                        const displayName = getRole(t.type).displayName.toLowerCase();
                                                        const name = t.name.toLowerCase();

                                                        if (name.includes(search) || displayName.includes(search)) {
                                                            return t;
                                                        }
                                                    })
                                                        .filter(it => !!it)
                                                        .filter(item => {
                                                            let roles = formik.values.roles || [];
                                                            return roles.findIndex(it => item.name === it) === -1;
                                                        });
                                                }}

                                            />;
                                        }}/>
                    <FormActions>
                        <Button type="submit">Create New Credentials</Button>
                    </FormActions>
                </Form>;
            }}
            </Formik>
        </Card>

    </div>;
}

RestApiTab.propTypes = {};