import * as React from "react";
import {useContext} from "react";
import GenericDataTable from "../../../common/ui/genericDataTable/genericDataTable";
import gql from "graphql-tag";
import Json from "../../../common/ui/json";
import * as log from "../../../common/log";
import {useQuery} from "@apollo/react-hooks";
import {useGraphqlLoadingComponent} from "../../../common/graphql";
import {RouterContext} from "../../../common/RouterContext";
import {usePagination} from "../../../common/hooks/usePagination";
import {useSort} from "../../../common/hooks/useSort";

const QUERY_SENSOR_DATA = gql`
    # {
    #  "devId": 1
    # }

    query device($devId: ID!, $page:PaginationInputType, $filter:[FilterInputType!], $sort: SortInputType) {
        sensorData(devId: $devId, page: $page, filter: $filter, sort: $sort) {
            id
            createdAt
            dataRaw
            type
            parseError
            datasource {
                id
                name
            }
        }
        device(id: $devId) {
            addr
            id
            name
            description
            configRaw
            deviceType {
                id
                name
            }
            app {
                id
                configRaw
            }
        }
    }
`;


const defaultTableConfig = {
    cols: [
        {
            "heading": "Received",
            "width": "10rem",
            "cell": {
                "format": "{{date createdAt 'DD.MM.YYYY HH:mm:ss'}}"
            }
        },
        {
            "heading": "Type",
            "width": "5rem",
            "cell": {
                "format": "{{type}}"
            }
        },
        {
            "heading": "Datasource",
            "width": "8rem",
            "cell": {
                "format": "{{datasource.name}}"
            }
        },
        {
            "heading": "Parse Error",
            "cell": {
                "format": "{{parseError}}"
            }
        },
    ]
};

export default function DeviceSensorData(props) {
    const {match} = useContext(RouterContext);
    const devId = match.params.deviceId;

    const sort = useSort();
    const page = usePagination(100);
    const sensorDataResult = useQuery(QUERY_SENSOR_DATA, {
        fetchPolicy: "cache-and-network",
        pollInterval: 5000,
        variables: {
            devId: devId,
            page: page.getGraphqlPageInput(),
            sort: sort.getGraphqlSortInput()
        }
    });

    const loading = useGraphqlLoadingComponent(sensorDataResult);
    if (loading) {
        return loading;
    }
    page.setPageItemCount(sensorDataResult.data.sensorData.length);

    log.Debug("SensorData result = ", sensorDataResult);

    let data = sensorDataResult.data;
    let appTableConfig = sensorDataResult.data.device.app &&
        sensorDataResult.data.device.app.configRaw &&
        JSON.parse(sensorDataResult.data.device.app.configRaw) &&
        JSON.parse(sensorDataResult.data.device.app.configRaw).ui.sensorDataTable;

    return <GenericDataTable
        id={"sensor-data-table"}
        tableConfigName={appTableConfig && `device-sensor-data-${data.device.deviceType.name}`}
        tableConfigDefault={appTableConfig || defaultTableConfig}
        items={sensorDataResult.data.sensorData.map((d) => {
            return {
                ...d,
                data: JSON.parse(d.dataRaw)
            };
        })}
        //selectedItem={data.parsedData[0]}
        renderDetails={(item) => {
            return <Json json={item}/>
        }}
        gqlResult={sensorDataResult}
        page={page}
        sort={sort}
    />
}