import React from "react";
import {ButtonGroup} from "@salesforce/design-system-react";
import PropTypes from "prop-types";
import Button from "../slds/buttons/button";

const Pagination = (props) => {
    const {page} = props;

    const knownTotal = page.pageInfo.total !== 0;

    const numFrom = page.pageInfo.offset + 1;
    let numTo = page.pageInfo.offset + page.pageInfo.limit;

    if (page.getPageItemCount() > 0) {
        numTo = page.pageInfo.offset + page.getPageItemCount();
    }

    return <div className="slds-grid">
        <ButtonGroup className="slds-p-right--small">
            <Button iconName={"back"}  onClick={() => page.prev()} disabled={!page.hasPrev()}>Prev</Button>
        </ButtonGroup>
        <div className="slds-align-middle">
            {knownTotal ? <div>
                    <span className="slds-text-title_bold slds-p-right--small">Page {page.currentPage()} / {page.totalPages()}</span> Items {numFrom} .. {numTo} of {page.pageInfo.total}
                </div>
                : <div>
                    <span className="slds-text-title_bold slds-p-right--small">Page {page.currentPage()}</span> Items {numFrom} .. {numTo}
                </div>
            }
        </div>
        <ButtonGroup className="slds-p-left--small">
            <Button iconName={"forward"} iconPosition={"right"} onClick={() => page.next()} disabled={!page.hasNext()}>Next</Button>
        </ButtonGroup>
    </div>
};

Pagination.propTypes = {
    page: PropTypes.any,
};

export default Pagination;
