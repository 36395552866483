import _ from "underscore";
import WmbusDetailComponent from "./wmbus";
import * as React from "react";
import PropTypes from "prop-types";
import {Accordion, AccordionPanel} from "../../common/ui/accordion";
import Json from "../../common/ui/json";
import {Log} from "../../common/log";


const deviceTraitInfo = {
    "wmbus-data": {
        parsedDataDetails: WmbusDetailComponent,
    }
};


// Pass deviceTraitInfo as string array
export function getDeviceTraitInfo(traits) {
    if (!_.isArray(traits)) {
        return [];
    }

    return traits.map(t => {
        if (deviceTraitInfo[t] === undefined) {
            return undefined;
        }
        return {name: t, ...deviceTraitInfo[t]};
    }).filter(it => it !== undefined);
}

export function ParsedDataDetailComponent(props) {
    let {data, traits} = props;

    const traitInfo = getDeviceTraitInfo(traits);

    Log.Debug("TraitInfo:", traits, traitInfo);

    if (traits.length === 0) {
        // without Accordion
        return <Json json={data}/>;
    }

    return <>
        {traitInfo.map((t, i) => {
            const DetailComponent = t.parsedDataDetails || undefined;
            return <DetailComponent key={i} data={data}/>;
        })}
        <Accordion>
            <AccordionPanel summary="Data (Json)" id="data-json-panel">
                <Json json={data}/>
            </AccordionPanel>
        </Accordion>
    </>;

}

ParsedDataDetailComponent.propTypes = {
    data: PropTypes.object,
    traits: PropTypes.arrayOf(PropTypes.string).isRequired,
};