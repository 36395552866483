import {useReducer, useRef} from "react";
import {Log} from "../log";
import _ from "lodash"

// example Filter: myProperty >= 10
// => [{"field": "myProperty", "op": "gte", "value": "10"}]
function filterFromString(filterString) {
// TODO: implement this or find more generic way of filtering including updating the backend?

    try {
        return JSON.stringify(filterString);
    } catch (e) {
        Log.Error("Failed to stringify filterString JSON", e)
        return []
    }

}

export function useFilter(initialFilters) {
    const [ticks, forceUpdate] = useReducer((i) => i + 1, 0);
    const filterRef = useRef(initialFilters || []);

    function getGraphqlFilterInput() {
        return filterRef.current
    }

    const setFilter = (filter) => {
        filterRef.current = filter;
    };

    function setFilterString(filterString) {
        let filter = [];
        try {
            filter = JSON.parse(filterString)
        } catch (e) {
            Log.Debug("Failed to parse filterString", filterString, e)
        }
        if (!_.isEqual(filterRef.current, filter)) {
            filterRef.current = filter;
            forceUpdate();
        } else {
            filterRef.current = filter;
        }
    }

    return {
        filter: filterRef.current,
        setFilter,
        setFilterString,
        getGraphqlFilterInput,
        forceUpdate
    }
}