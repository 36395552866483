import {Log} from "../../common/log";
import {NotifyUser} from "../../common/userNotification";
import {GenericDataTableConfigForm} from "../../common/ui/genericDataTable/genericDataTableConfigForm";
import {CancelButtonField, FormActions, SubmitButtonField} from "../../common/ui/form/formElements";
import {Formik} from "formik";
import * as React from "react";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {MUTATE_UPDATE_DEVICE_TYPE, QUERY_DEVICE_TYPE} from "../queries";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {DEFAULT_DEVICE_TABLE_CONFIG} from "./deviceTypesDetailPage";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import PropTypes from "prop-types"

const DeviceTableConfigTab = (props) => {
    const {deviceTypeId} = props;
    const match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    Log.Debug("DeviceTypesDetailPage.props", props);
    Log.Debug("DeviceTypesDetailPage.match", match);

    const deviceTypeResult = useQuery(QUERY_DEVICE_TYPE, {
        variables: {
            id: deviceTypeId
        }
    });

    const [updateDeviceType] = useMutation(MUTATE_UPDATE_DEVICE_TYPE, {
        variables: {id: match.params.id},
        refetchQueries: [{
            query: QUERY_DEVICE_TYPE,
            variables: {
                id: deviceTypeId,
            }
        }]
    });

    function restoreDefaultDeviceTableConfig() {
        return updateDeviceType({
            variables: {
                id: deviceTypeId,
                input: {
                    deviceTableConfig: JSON.stringify(DEFAULT_DEVICE_TABLE_CONFIG),
                }
            }
        }).catch((err) => {
            NotifyUser.Error("Failed to save TableConfig", err);
        });
    }


    const loadingHW = useGraphqlLoadingComponent(deviceTypeResult);
    if (loadingHW) {
        return loadingHW;
    }
    const deviceType = deviceTypeResult.data.deviceType;

    Log.Debug("deviceType", deviceType);

    return <Formik
        initialValues={(deviceType.deviceTableConfigRaw && JSON.parse(deviceType.deviceTableConfigRaw)) || {}}
        enableReinitialize={true}
        initialStatus={{
            readOnly: true,
            canEdit: true
        }}
        onSubmit={(values, actions) => {
            Log.Debug("SUBMIT", values);
            updateDeviceType({
                variables: {
                    id: deviceType.id,
                    input: {
                        deviceTableConfig: JSON.stringify(values),
                    }
                }
            }).catch((err) => {
                NotifyUser.Error("Failed to save Table Config", err);
            }).finally(() => {
                actions.setSubmitting(false);
            });
        }}
        render={formik => {
            return <>
                <GenericDataTableConfigForm
                    id={"generic-table-config-form"}
                    formik={formik}
                    onCancel={() => {
                        Log.Debug("Cancel")
                    }}
                    onRestoreDefault={() => restoreDefaultDeviceTableConfig()}
                />
                <FormActions>
                    <SubmitButtonField formId={"generic-table-config-form"}>Save</SubmitButtonField>
                    <CancelButtonField>Cancel</CancelButtonField>
                </FormActions>
            </>;
        }}/>
};
DeviceTableConfigTab.propTypes = {
    deviceTypeId: PropTypes.string.isRequired,
};

export default DeviceTableConfigTab