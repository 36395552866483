import React from "react";
import {Field, Form, Formik} from "formik";
import {CancelButtonField, FormActions, SldsFormElementCompound, SldsFormElementRow, SldsInput, SldsSelectField, SldsTextarea, SubmitButtonField} from "../../../common/ui/form/formElements";
import * as PropTypes from "prop-types";
import * as log from "../../../common/log";
import {useAuthContext} from "../../../common/context/authContext";
import {NotifyUser} from "../../../common/userNotification";
import SingleLookupField from "../../../common/ui/lookup/singleLookupField";
import {useMutation, useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {DeviceDatasourceType} from "../../../model/device";
import Button from "../../../common/slds/buttons/button";
import {DataSourceLinkStatusIcon} from "./datasourceLinkStatusIcon";
import {DeviceAppLookupField} from "../../../components/device/DeviceAppLookupField";
import DeviceTypeLookupField from "../../../components/deviceType/deviceTypeLookupField";

const QUERY_DEVICE_TYPES = gql`
    query ($search: String) {
        deviceTypes(page: {limit: 10, offset: 0}, search: $search) {
            id
            name
            displayName
            configProperties
        }
    }
`;

const QUERY_DEVICE_DATASOURCE_CONNECTION = gql`
    query deviceConnection($devId: ID!) {
        device(id: $devId) {
            id
            datasource {
                type
                connected
            }
        }
    }
`;

const MUTATE_SYNC_DEVICE_DATASOURCE = gql`
    mutation sync($devId: ID!) {
        syncDeviceWithDatasource(devId: $devId) {
            id
        }
    }
`;

const EditDeviceForm = (props) => {
    const auth = useAuthContext();
    const {onUpdate, device} = props;

    const deviceTypesResult = useQuery(QUERY_DEVICE_TYPES);
    const [syncDeviceDs] = useMutation(MUTATE_SYNC_DEVICE_DATASOURCE, {
        variables: {
            devId: device.id,
        },
        refetchQueries: [{
            query: QUERY_DEVICE_DATASOURCE_CONNECTION,
            variables: {
                devId: device.id,
            }
        }]
    });

    log.Debug("EditDeviceForm:", device);

    const syncDeviceWithDatasource = (dev) => {
        syncDeviceDs().then(() => {
            NotifyUser.Info("Datasource synchronized.");
        }).catch((e) => {
            NotifyUser.Error("Failed to sync Datasource.", e);
        });
    };

    const isAdmin = auth.hasRole("admin");
    const isOrgAdmin = auth.hasRole("org-admin");

    return <div>
        <Formik
            initialValues={device}
            enableReinitialize={true}
            initialStatus={{
                readOnly: true,
                canEdit: isAdmin || isOrgAdmin
            }}
            validate={(values, props) => {
                let errors = {};
                if (!values.name) {
                    errors.name = "Name must not be empty";
                }
                return errors;
            }}
            onSubmit={(values, actions) => {
                log.Debug("Submit: ", values, actions);
                onUpdate(values, actions).then(() => {
                }, (err) => {
                    NotifyUser.Error("Failed to update device.", err);
                }).finally(() => {
                    actions.setSubmitting(false);
                });
            }}
            render={(formik) => {
                const {readOnly} = formik.status;
                const isOrgAdmin = auth.hasRole("org-admin");

                return <Form className="slds-form slds-form-element_stacked">
                    <Field component={SldsInput} name="name" placeholder="Name of the device" id="name" label="Name"
                           readOnly={readOnly}/>
                    <Field component={SldsInput} name="addr" placeholder="Address of the device" id="addr" label="Address"
                           readOnly={readOnly || isOrgAdmin}/>
                    <Field component={SldsTextarea} name="description" placeholder="Description of the device" id="description"
                           label="Description" rows={4} readOnly={readOnly}/>
                    <DeviceTypeLookupField/>
                    <DeviceAppLookupField
                        name={"app"}
                        orgId={formik.values?.organisation?.id}
                    />
                    <SldsFormElementCompound>
                        <SldsFormElementRow>
                            <SldsSelectField className="slds-size_1-of-2" label={"Datasource"} name={"datasource.type"} options={[
                                {
                                    "value": DeviceDatasourceType[""].value,
                                    "label": DeviceDatasourceType[""].label,
                                },
                                {
                                    "value": DeviceDatasourceType.CHIRPSTACK_GLOBAL.value,
                                    "label": DeviceDatasourceType.CHIRPSTACK_GLOBAL.label,
                                }
                            ]}>
                            </SldsSelectField>
                            {isAdmin && formik.values.datasource.type ? <div className="slds-align-bottom">
                                <DataSourceLinkStatusIcon datasource={formik.values.datasource}/>
                                {!device.datasource.connected ? <Button className="slds-m-left--small" iconName={"sync"} onClick={(e) => syncDeviceWithDatasource(device)}>Sync</Button> : null}
                            </div> : null
                            }
                        </SldsFormElementRow>
                    </SldsFormElementCompound>
                    {/* The old shitty one
                    <Field component={DeviceTypeSelector} readOnly={readOnly || isOrgAdmin}
                           name="deviceType" placeholder="Type of the device"
                           id="device-type" label="Device Type" deviceTypes={deviceTypes}/>
                           */}

                    <FormActions hidden={readOnly}>
                        <SubmitButtonField>Save</SubmitButtonField>
                        <CancelButtonField>Cancel</CancelButtonField>
                    </FormActions>
                </Form>;
            }}
        />

    </div>;
};

export default EditDeviceForm;


EditDeviceForm.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    device: PropTypes.object.isRequired,
    deviceTypes: PropTypes.array.isRequired,
    canEdit: PropTypes.bool,
};
