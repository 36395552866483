import {useQuery} from "@apollo/react-hooks";
import {Log} from "../log";
import {download} from "../download";
import {compileHandlebars} from "../cachedHandlebarsCompiler";

export const toCsvLine = (strings) => strings
        .map(s => "\"" + s.toString().replace("\"", "\"\"") + "\"")
        .join(";")
    + "\n";

export const useCsvExport = (query, options) => {
    const result = useQuery(query, {
        skip: true,
        fetchPolicy: "network-only",
        variables: options.variables
    });

    return {
        export: (tableConfig) => {
            Log.Debug("CSV Export with Table config", tableConfig);
            let csvData = "";
            const exportedCols = tableConfig.cols.filter(col => col.csvFormat?.length > 0);
            csvData += toCsvLine(exportedCols.map(col => col.heading || ""));

            Log.Debug("Result", result);
            result.refetch().then(refetchResult => {
                //Log.Debug("refetchResult", refetchResult);
                options.dataExtractor(refetchResult).forEach(item => {
                    //Log.Debug("item", item);
                    csvData += toCsvLine(exportedCols.map(col => compileHandlebars(col.csvFormat || col.cell?.format, item)));
                });
                download(csvData, "export.csv", "text/csv");
            });
        }
    };
};