import React from "react";
import Page from "../../../common/ui/page";
import {Link, useRouteMatch} from "react-router-dom";
import {FieldArray, Formik} from "formik";
import {useMutation, useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {useAuthContext} from "../../../common/context/authContext";
import {Form} from "../../../common/ui/form/formik";
import {CancelButtonField, FormActions, SldsButtonGroup, SldsFormElementCompound, SldsFormElementRow, SldsInputField, SubmitButtonField} from "../../../common/ui/form/formElements";
import Button from "../../../common/slds/buttons/button";
import {useNotificationContext} from "../../../notifications/notificationContext";


export default function WmbusConfigPage(props) {
    const match = useRouteMatch();
    const auth = useAuthContext();

    let wmbusKeysResult = useQuery(gql`
        query ($orgId: ID!){
            getOrganisation(id: $orgId) {
                id
                name
                wmbusKeys {
                    keys {
                        id
                        key
                    }
                }
            }
        }
    `, {
        variables: {
            orgId: auth.organisationId(),
        }
    });

    let [updateOrganisation] = useMutation(gql`mutation($orgId: ID!, $organisation: OrganisationInput!){
        updateOrganisation(id: $orgId, input: $organisation) {
            wmbusKeys {
                keys {
                    id
                    key
                }
            }
        }}
    `, {
        variables: {
            orgId: auth.organisationId(),
        }
    });

    const wmbusKeys = wmbusKeysResult?.data?.getOrganisation?.wmbusKeys;
    const notify = useNotificationContext();

    return <Page
        trail={[<Link to={match.url} key={1}>wMbus</Link>]}
        title={"wMbus"}
    >
        <div className="slds-p-around--small">

            <div className="slds-text-heading--medium slds-m-bottom--x-small">wMbus Keys</div>
            <Formik
                onSubmit={(values, actions) => {
                    updateOrganisation({
                        variables: {
                            organisation: {
                                wmbusKeys: JSON.stringify(values.wmbusKeys),
                            }
                        }
                    }).then(() => {
                        notify.info("Updated wMbus Keys");
                    }).catch((e) => {
                        notify.error("Failed to update wMbus Keys", e);
                    });
                }}
                initialValues={{
                    wmbusKeys: wmbusKeys,
                }}
                initialStatus={{
                    readOnly: true,
                    canEdit: true,
                }}
                enableReinitialize={true}
            >
                <Form>
                    <FieldArray name={"wmbusKeys.keys"}>
                        {(arrayHelpers) => {
                            const values = arrayHelpers.form.values;
                            const {readOnly} = arrayHelpers.form.status;
                            const keys = values?.wmbusKeys?.keys;
                            return <>
                                {keys?.map((k, idx) => {
                                    return <SldsFormElementCompound key={idx}>
                                        <SldsFormElementRow>
                                            <SldsInputField className="slds-size_1-of-3" name={`wmbusKeys.keys.${idx}.id`} label={"Meter Id"}
                                                            validate={(value) => {
                                                                return /(\*|[0-9]{8})/.test(value) ? undefined : "Meter ID must be 8 digits or '*' for any";
                                                            }}
                                            />
                                            <SldsInputField className="slds-size_1-of-3" name={`wmbusKeys.keys.${idx}.key`} label={"Key"}
                                                            validate={(value) => {
                                                                return /[a-fA-F0-9]{32}/.test(value) ? undefined : "Key must be 16 bytes Hex (0-9, A-Z)";
                                                            }}
                                            />

                                            {!readOnly && <SldsButtonGroup className="xslds-col_bump-left slds-shrink slds-align-bottom">
                                                <Button iconName={"arrowup"} iconSize={"small"} disabled={idx === 0} onClick={() => arrayHelpers.move(idx, idx - 1)}/>
                                                <Button iconName={"arrowdown"} iconSize={"small"} disabled={idx === keys.length - 1} onClick={() => arrayHelpers.move(idx, idx + 1)}/>
                                                <Button className={"slds-button_icon-error"} iconName={"delete"} iconSize={"small"} onClick={() => arrayHelpers.remove(idx)}/>
                                            </SldsButtonGroup>}
                                        </SldsFormElementRow>
                                    </SldsFormElementCompound>;
                                })}
                                {!readOnly && <Button iconName={"add"} onClick={() => arrayHelpers.push({})}>Add Key</Button>}
                            </>;
                        }}
                    </FieldArray>
                    <FormActions>
                        <SubmitButtonField>Save</SubmitButtonField>
                        <CancelButtonField>Cancel</CancelButtonField>
                    </FormActions>
                </Form>

            </Formik>
        </div>
    </Page>;
}