import React, {useEffect, useState} from "react";
import UserHomePage from "./userhome/userHomePage";
import AuthPage from "./auth/authPage";
import WmbusApiModule from "./wmbusapi/wmbusApiModule";
import OrganisationPage from "./organisation/organisationPage";
import ConfigurationPage from "./configuration/configurationPage";
import HardwareActivationPage from "./hardwareActivation/hardwareActivationPage";
import {Route, Switch} from "react-router-dom";
import {Header} from "./header/header";
import TopNavigation from "./header/topNavigation";
import {useAuthContext} from "./common/context/authContext";
import TopNavigationLink from "./header/topNavigationLink";
import TopNavigationDropdown from "./header/topNavigationDropdown";
import TopNavigationDropdownLink from "./header/topNavigationDropdownLink";
import HeaderOrganisationLogo from "./header/HeaderOrganisationLogo";
import {Log} from "./common/log";

const NonAppModule = (props) => {
    const auth = useAuthContext();

    // Reload the page when auth state changes, e.g. switching the org
    let [reload, setReload] = useState(0);
    useEffect(() => {
        auth.changeListeners.push((auth) => {
            // Leads to endless loop on login
            //setReload(Math.random());
        });
    });


    return <>
        <Header logo={<HeaderOrganisationLogo/>} logoLinkTo={'/'}/>
        <TopNavigation
            moduleName={"IoT Platform"}
            secondary={<>
                {auth.isLoggedIn() && <>
                    <TopNavigationLink to={"/organisation/devices"} label={"Devices"}/>
                    <TopNavigationLink to={"/organisation/deviceData"} label={"Data"}/>
                    <TopNavigationLink to={"/organisation/integrations"} label={"Integrations"}/>
                </>}
                {(auth.hasRole("org-admin") || auth.hasRole("admin")) && <>
                    <TopNavigationLink to={"/organisation/config"} label={"Organisation"}/>
                </>}
                {auth.hasRole("admin") && <>
                    <TopNavigationLink to={"/configuration"} label={"Configuration"}/>
                </>}
                <TopNavigationDropdown label={"Tools"}>
                    <TopNavigationDropdownLink to={"/wmbus"} label={"wMbus Parser API"}/>
                    <TopNavigationDropdownLink to={"/hardwareActivation"} label={"Hardware Activation"}/>
                </TopNavigationDropdown>
            </>}
        />

        <Switch>
            <Route exact path={"/"} component={UserHomePage}/>
            <Route path={"/auth"} component={AuthPage}/>
            <Route path={"/wmbus"} component={WmbusApiModule}/>
            <Route path={"/organisation"} component={OrganisationPage}/>
            <Route path={"/configuration"} component={ConfigurationPage}/>
            <Route path={"/hardwareActivation/:activationCode?"} component={HardwareActivationPage}/>
            <Route render={() => <div>
                <div>Not Found</div>
            </div>}/>
        </Switch>
    </>;
};

export default NonAppModule;