import React from "react";
import Url from "../common/url";
import {Link, Redirect, Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import ApiTab from "./apiTab";
import ParserTab from "./parserTab";
import Page from "../common/ui/page";
import {Log} from "../common/log";
import {Tab, TabNavigation} from "../common/ui/tabNavigation";


const WmbusApiModule = (props) => {
    const match = useRouteMatch();
    const location = useLocation();
    Log.Debug("match", match);
    return <TabNavigation tabs={[
        <Tab key={"parser"} to={`${match.path}/parser`}>Parser</Tab>,
        <Tab key={"api"} to={`${match.path}/api`}>API</Tab>
    ]}>

        <Page title={"wMbus API"}
              trail={[<Link to={"/"} key={1}>wMbus API</Link>]}
        >
            <Switch>
                <Route path={Url.join(match.path, "/parser")} component={ParserTab}/>
                <Route path={Url.join(match.path, "/api")} component={ApiTab}/>
                <Route path={Url.join(match.path, "/")}
                       render={() => <Redirect to={Url.join(match.url, "/parser") + location.search}/>}/>
            </Switch>
        </Page>
    </TabNavigation>;
};

export default WmbusApiModule;