import * as React from "react";
import {useState} from "react";
import Page from "../../common/ui/page";
import {QUERY_HARDWARE_LIST} from "../queries";
import {useQuery} from "@apollo/react-hooks";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import DataTableColumn from "../../common/ui/data-table/column";
import CustomDataTableCell from "../../common/ui/data-table/customCell";
import {Link} from "react-router-dom";
import {ButtonGroup} from "@salesforce/design-system-react";
import AddHardwareDialog from "./addHardwareDialog";
import {usePagination} from "../../common/hooks/usePagination";
import {useSearch} from "../../common/hooks/useSearch";
import {useHistory} from "react-router";
import Button from "../../common/slds/buttons/button";


const HardwareListTable = (props) => {

    return <GenericDataTable id={"hardware-table"} items={props.result.data && props.result.data.devices || []} tableConfigDefault={{}}
                             gqlResult={props.result}
                             page={props.page} search={props.search}>
        <DataTableColumn label="ID" property={"id"}>
            <CustomDataTableCell key="id" render={(props) => (
                <Link to={`./hardware/${props.item.id}`}>{props.item.displayName ? props.item.displayName : props.item.id}</Link>
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Address" property="addr" title={"Addr"}>
            <CustomDataTableCell key="id" render={(props) => (
                <Link to={`./hardware/${props.item.id}`}>{props.item.addr}</Link>
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Name" property="name" title={"Name"}>
            <CustomDataTableCell key="id" render={(props) => (
                <Link to={`./hardware/${props.item.id}`}>{props.item.name}</Link>
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Device Type" property={"deviceType"}>
            <CustomDataTableCell key="type" render={(props) => (
                <Link to={`/configuration/device-types/${props.item.deviceType.id}`}>{props.item.deviceType.displayName}</Link>
            )}/>
        </DataTableColumn>

        <DataTableColumn label="App" property={"app"}>
            <CustomDataTableCell key="type" render={(props) => (
                props.item.app ? <Link to={`/configuration/applications/${props.item.app.id}`}>{props.item.app.name}</Link> : null
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Organization" property={"Organization"}>
            <CustomDataTableCell key="type" render={(props) => (
                props.item.organisation ? <Link to={`/configuration/organisations/${props.item.organisation.id}`}>{props.item.organisation.name}</Link> : null
            )}/>
        </DataTableColumn>
    </GenericDataTable>;
};

const HardwareListPage = () => {
    const page = usePagination();
    const search = useSearch();
    const history = useHistory();
    const result = useQuery(QUERY_HARDWARE_LIST, {
        variables: {
            page: page.getGraphqlPageInput(),
            search: search.getGraphqlSearchInput()
        }
    });

    const [showNewHardwareModal, setShowNewHardwareModal] = useState(false);

    return <Page
        trail={[<Link to="./hardware" key={1}>Hardware List</Link>]}
        title={"Hardware"}
        actions={<ButtonGroup>
            <Button iconName={"add"} onClick={() => setShowNewHardwareModal(true)}>New Hardware</Button>
            <Button iconName={"upload"} onClick={() => history.push("/configuration/hardware/import")}>Import</Button>
        </ButtonGroup>}
    >
        <AddHardwareDialog isOpen={showNewHardwareModal} onRequestClose={() => setShowNewHardwareModal(false)}
                           onAdded={(hw) => history.push("/configuration/hardware/" + hw.id)}/>
        <HardwareListTable result={result} page={page} search={search}/>
    </Page>;
};

export default HardwareListPage;