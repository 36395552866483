import * as React from "react";
import {useAuthContext} from "../context/authContext";
import {Accordion, AccordionPanel} from "./accordion";


export const DangerZone = (props) => {
    const {children} = props;
    return useAuthContext().hasRole("admin") ?
        <Accordion id="base-example-accordion" className={"slds-m-top--medium"}>
            <AccordionPanel
                expanded={false}
                id={"danger-zone"}
                key={"danger-zone"}
                summary={"Danger Zone"}
            >
                {children}
            </AccordionPanel>
        </Accordion> : null;
};