import {useQuery} from "@apollo/react-hooks";
import SingleLookupField from "../../common/ui/lookup/singleLookupField";
import * as React from "react";
import gql from "graphql-tag";


export default function DeviceTypeLookupField(props) {
    const deviceTypesResult = useQuery(gql`
        query ($search: String) {
            deviceTypes(page: {limit: 10, offset: 0}, search: $search) {
                id
                name
                displayName
                configProperties
            }
        }
    `);

    return <SingleLookupField
        name={"deviceType"} label={"Device Type"}
        subtitleExtractor={it => it.id + " - " + it.name}
        titleExtractor={it => it.displayName}
        loadSuggestions={keyword => deviceTypesResult.refetch({search: keyword})
            .then(result => result.data.deviceTypes)
        }
    />;
}