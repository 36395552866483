import React from "react";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import {useRouteMatch} from "react-router-dom";
import GenericDataTable from "../../../common/ui/genericDataTable/genericDataTable";
import Json from "../../../common/ui/json";
import DataTableColumn from "../../../common/ui/data-table/column";
import moment from "moment";
import {ButtonGroup, Checkbox} from "@salesforce/design-system-react";
import Button from "../../../common/slds/buttons/button";
import {Log} from "../../../common/log";


const QUERY_DOWNLINKS = gql`
    query deviceDownlinks($devId: ID!){
        downlinkList(devId: $devId, showCompleted: true) {
            id
            createdAt
            type
            sent
            completed
            confirmable
            confirmed
            data
            device{
                id

            }
        }
    }
`;

export default function DeviceDownlinkData(props) {
    const match = useRouteMatch();
    const devId = match.params.deviceId;

    const downlinksResult = useQuery(QUERY_DOWNLINKS, {
        variables: {
            devId: devId,
        },
        fetchPolicy: "network-only",
    });

    const downlinks = downlinksResult.data?.downlinkList || [];

    const completedDl = downlinks.filter(it => it.completed);
    const queuedDl = downlinks.filter(it => !it.completed);


    return <div className="slds-grid slds-grid_vertical slds-p-horizontal--small">
        <div className="slds-col_bump-left slds-float_right">
            <ButtonGroup className="slds-p-bottom--x-small slds-p-horizontal--small">
                <Button iconCategory={"utility"} iconName={"refresh"} iconVariant={"border"}
                        onClick={() => downlinksResult.refetch()}
                />
            </ButtonGroup>
        </div>

        <div className="slds-text-heading--medium">Queued downlinks</div>
        <GenericDataTable id="downlinks" fixedLayout={true} items={queuedDl} renderDetails={(item) => {
            Log.Debug("props", item);
            return <Json jsonString={item.data}/>;
        }}>
            <DataTableColumn label={"Created"} render={(props) => moment(props.item.createdAt).format("DD.MM.YYYY HH:mm:ss")}/>
            <DataTableColumn label={"Type"} property={"type"}/>
            <DataTableColumn label={"Confirmable"} id={"cc"} render={(props) => <Checkbox checked={props.item.confirmable} readOnly/>}/>
            <DataTableColumn label={"Confirmed"} render={(props) => <Checkbox checked={props.item.confirmed} readOnly/>}/>
            <DataTableColumn label={"Completed"} render={(props) => <Checkbox checked={props.item.completed} readOnly/>}/>
            <DataTableColumn label={"Sent At"} render={(props) => {
                if (!props.item.sent) {
                    return "not yet";
                }
                return moment(props.item.sent).format("DD.MM.YYYY HH:mm:ss");
            }}/>
        </GenericDataTable>
        <div className="slds-text-heading--medium">Completed downlinks</div>
        <GenericDataTable id="downlinks" fixedLayout={true} items={completedDl} renderDetails={(item) => {
            return <Json jsonString={item.data}/>;
        }}>
            <DataTableColumn label={"Created"} render={(props) => moment(props.item.createdAt).format("DD.MM.YYYY HH:mm:ss")}/>
            <DataTableColumn label={"Type"} property={"type"}/>
            <DataTableColumn label={"Confirmable"} id={"cc"} render={(props) => <Checkbox checked={props.item.confirmable} readOnly/>}/>
            <DataTableColumn label={"Confirmed"} render={(props) => <Checkbox checked={props.item.confirmed} readOnly/>}/>
            <DataTableColumn label={"Completed"} render={(props) => <Checkbox checked={props.item.completed} readOnly/>}/>
            <DataTableColumn label={"Sent At"} render={(props) => {
                if (!props.item.sent) {
                    return "not yet";
                }
                return moment(props.item.sent).format("DD.MM.YYYY HH:mm:ss");
            }}/>
        </GenericDataTable>
    </div>
        ;
};