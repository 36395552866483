import {HasPermission} from "../common/ui/permissions";
import React from "react";
import {useAuthContext} from "../common/context/authContext";
import {Link, useHistory, useLocation} from "react-router-dom";

export const LoginButton = (props) => {
    let auth = useAuthContext();
    let location = useLocation();
    let history = useHistory();

    return <div className="slds-grid">
        <div className="slds-col slds-align-bottom slds-m-right--large slds-no-flex">
            <HasPermission isLoggedIn={true}>
                <div className="slds-media">
                    <div className="slds-media__figure">
                        <svg aria-hidden="true" className="slds-icon slds-icon-standard-user">
                            <use xlinkHref="/assets/icons/standard-sprite/svg/symbols.svg#user"></use>
                        </svg>
                    </div>
                    <div className="slds-media__body">
                        <div className="slds-grid">
                            <div className="slds-col slds-truncate slds-no-flex slds-m-right--small">
                                <p className="slds-text-heading--label">Account</p>
                                <div className="slds-truncate">
                                    <Link to="/auth/my-account">{auth.name()}</Link>
                                </div>
                            </div>
                            <div className="slds-col">
                                <a className="slds-button slds-button--neutral slds-float--left"
                                   onClick={() => {
                                       auth.logout();
                                       history.push("/");
                                   }}>Logout</a>
                            </div>
                        </div>

                    </div>

                </div>
            </HasPermission>

            <HasPermission isLoggedIn={false}>
                <div className="slds-media">
                    <Link
                        className="slds-button slds-button--neutral"
                        to={`/auth/login?r=${location.pathname}`}>Login</Link>
                    <Link
                        className="slds-m-left--small slds-align-bottom"
                        to={`/auth/register?r=${location.pathname}`}>
                        Create account</Link>
                </div>
            </HasPermission>

        </div>
        <div className="slds-col slds-align-bottom slds-m-bottom--xsmall- slds-no-flex">
            <div>&#xBB; <a href="http://www.lobaro.com/contact/" target="_blank">Legal Disclosure</a></div>
        </div>
    </div>
}