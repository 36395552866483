import {useMutation, useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {Modal} from "../../common/slds";
import {Formik} from "formik";
import {Log} from "../../common/log";
import {Form} from "../../common/ui/form/formik";
import {CancelButtonField, FormActions, SldsInputField, SubmitButtonField} from "../../common/ui/form/formElements";
import * as React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import SingleLookupField from "../../common/ui/lookup/singleLookupField";

const AddUserDialog = (props) => {
    let {isOpen, onRequestClose} = props;

    const [createUser] = useMutation(gql`
        mutation($input: UserInput) {
            createUser(input: $input) {
                id
            }
        }
    `, {
        onCompleted: (data) => props.onAdded(data.createUser)
    });

    const organisationListResult = useQuery(gql`
        query($search: String) {
            getOrganisationList(search: $search) {
                id
                name
            }
        }`, {
        variables: {
            page: {
                offset: 0,
                limit: 10
            }
        },
        skip: true
    });

    return <Modal isOpen={isOpen} onRequestClose={onRequestClose} title={"Create User"}>
        <Formik
            initialValues={{}}
            onSubmit={(values, actions) => {
                return createUser({
                    variables: {
                        input: {
                            login: values.login,
                            name: values.name,
                            email: values.email,
                            organisationId: values.organisation && values.organisation.id
                        }
                    }
                }).then(() => {
                    onRequestClose();
                }, (e) => {
                    actions.setFieldError("global", e);
                    Log.Error("Failed to create hardware", e);
                })
            }}
            validationSchema={Yup.object().strict().shape({
                email: Yup.string().required().trim().email(),
            })}

        >
            <Form>
                <SldsInputField name={"login"} label={"Login"} placeholder={"Username"} required={true}/>
                <SldsInputField name={"email"} label={"Email"} placeholder={"mail@example.org"} required={true}/>
                <SldsInputField name={"name"} label={"Name"} placeholder={"Max Mustermann"} required={true}/>
                <SingleLookupField name={"organisation"} label={"Organisation"}
                                   loadSuggestions={(keyword) => organisationListResult
                                  .refetch({search: keyword})
                                  .then(result => result.data.getOrganisationList)
                              }
                                   titleExtractor={it => it.name}
                                   subtitleExtractor={it => it.id}
                />
                <FormActions>
                    <SubmitButtonField>Create</SubmitButtonField>
                    <CancelButtonField onClick={onRequestClose}>Cancel</CancelButtonField>
                </FormActions>
            </Form>
        </Formik>
    </Modal>;
};

AddUserDialog.propTypes = {
    onAdded: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func,
    isOpen: PropTypes.bool,
};

export default AddUserDialog;